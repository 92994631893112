.dropdowntextbox .MuiChip-root {
  border-radius: 0.2rem;
  background-color: #ededed;
  margin-right: 0.4rem;
  height: 1.5rem;
  margin-top: 0.4rem;
}
.dropdowntextbox input.MuiInputBase-input {
  padding: 0;
  height: 3rem;
  margin-top: 0.4rem;
  font-family: var(--defaultFontFamily);
}

.dropdowntextbox div.MuiInputBase-root {
  padding-bottom: 0.7rem;
  padding-top: 0.17rem;
  padding-left: 1.3rem;
}

.dropdowntextbox {
  font-family: var(--defaultFontFamily);
  display: inline;
}

.dropdownsearchable-paper .MuiList-root::-webkit-scrollbar {
  width: 0.75rem;
}

/* scrollbar - track */
.dropdownsearchable-paper .MuiList-root::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}

/* scrollbar - thumb */
.dropdownsearchable-paper .MuiList-root::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-background);
  border-radius: 0.75rem;
  border: 0.2rem solid var(--scrollbar-background);
}

.dropdownsearchable-paper .MuiList-root {
  border: 0.2rem solid var(--secondary-border-color);
}

.dropdownsearchable-container .MuiOutlinedInput-notchedOutline {
  border: 0.2rem solid var(--secondary-border-color);
}
.dropdowntextbox .dropdown-chevrondown-icon .MuiSelect-icon {
  float: right;
  color: var(--primary-icon-color);
  margin: -2.7rem 1.3rem 0px 0px;
  font-size: 1.6rem;
  position: relative;
}
