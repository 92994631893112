.alert-table table {
  border-spacing: 0;
  border: 1px solid white;
}

.alert-header {
  color: var(--page-container-text);
}

.alert-table td {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  padding-right: 1.6rem;
}
