.side-indicator {
  position: relative;
  width: 5.8rem;
  margin-left: -1.4rem;
  margin-top: -0.6rem;
  margin-bottom: -0.6rem;
  border-radius: 0.4rem 0px 0px 0.4rem;
}

.toast-header,
.side-indicator {
  height: 4rem;
}

.toast-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
}

.success-background {
  background-color: #f2faeb !important;
}

.error-background {
  background-color: #fbefed !important;
}

.success-alert {
  background-color: #48850b !important;
}

.error-alert {
  background-color: #cb391f !important;
}

.toast {
  position: fixed;
  top: 4.5rem;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 100rem !important;
  padding: 0px !important;
  border-radius: 0.4rem !important;
  border-width: 0px !important;
}

.message-text {
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: var(--defaultFontFamily);
  color: black;
  flex-grow: 2;
  white-space: pre-wrap;
}

.toast-header > button {
  margin-right: 0.8rem;
  margin-bottom: 0.2rem !important;
  font-size: 2.4rem;
}

.toast.show {
  z-index: 1005;
}



/* ToastModal.css */
.toast-backdrop 
{ 
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  background-color: 
  rgba(0, 0, 0, 0.5); 
  z-index: 1004; /* Just below the toast z-index */ 
}