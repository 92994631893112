.operations-view {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.operations-view .operations-view-main-content {
    flex: 1;
    overflow: hidden;
}

.operations-view .flight-list-table-container {
    height: calc(100% - 4.2rem);
}

/* Swap mode new */
.operations-view.swap-mode-active .flights-gantt-footer {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    background: var(--flight-nav-bar-background);
    border-top: 0.6rem solid var(--gantt-flight-toggle-selected-background);
    border-bottom: 0.6rem solid var(--gantt-flight-toggle-selected-background);
    height: 6.8rem;
    z-index: 1010;    
}

.operations-view.swap-mode-active .swap-mode-border-overlay .operations-view-main-content {
    margin-left: -0.6rem;
}

.operations-view.swap-mode-active .swap-mode-border-overlay {
    border-left: 0.6rem solid var(--gantt-flight-toggle-selected-background);
    border-right: 0.6rem solid var(--gantt-flight-toggle-selected-background);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Summary footer overrides */
.operations-view .summary-panel-footer {
    position: relative;
    width: 100%;
}

.operations-view.swap-mode-active.summary-panel-mini .summary-panel-footer {
    height: var(--constant-gantt-bottom-summary-mini-height);
}

.operations-view.swap-mode-active.summary-panel-expanded .summary-panel-footer {
    height: var(--constant-gantt-bottom-summary-expanded-height);
}