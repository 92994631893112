.identity-block,
.mini-summary-container {
    display:flex;
    font-size: 1.6rem;
}
.mini-summary-container{
    margin-left: 1.6rem;
    height: var(--constant-gantt-bottom-summary-mini-height);
}

.mini-summary-container .flight-number {
    font-size: 1.6rem;
    margin-top: 2px;

}
.mini-summary-container .irrops-code {
    background-color: #DE750C;
    padding: 2px 4px 2px 4px;
    font-size: 1.2rem;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 2px;
    margin-left:1rem;
    font-weight: bold;
    /* margin-right:1.6rem; */
}
.mini-summary-container .vertical-line {
    font-weight: bold;
    height:75%;
    width:2px;
    background-color:white;
    margin-top:4px;
    margin-left:1.6rem;
}
.mini-summary-container .float-left {
    margin-left: 1.6rem;
    margin-top:-3px;
}
.mini-summary-container .float-left.block-space {
    width:3rem;
}
.mini-summary-container span.timezone-info {
    font-size: 1.2rem;
    font-weight:bold;
    color: var(--gray);
    padding-left: 0.2rem;;
}