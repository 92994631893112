.flight-list-table-action-menu-popover {
  background-color: var(--dropdown-menu-background);
  color: black;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: var(--flight-action-menu-box-shadow);
  border-radius: 0.5rem;
  width: 18.5rem;
}

.puck-container .flight-action-menu .action-menu.show-overlay {
  visibility: visible;
}

.popover-flight-action-menu {
  border: none !important;
  border-radius: 0.5rem !important;
}

.popover-flight-action-menu.popover {
  z-index: 1000;
}

.popover-flight-action-menu > div:first-child::before {
  border-bottom-color: rgb(0 0 0 / 12%) !important;
}

.flight-list-table-action-menu-item-dot {
  float: right;
  padding-right: 0.5rem;
  padding-top: 0.6rem;
}

.flight-list-table-action-menu-item {
  display: inline-block;
  width: calc(90%);
  padding: 0.1rem 1rem;
  background-color: var(--dropdown-menu-background);
  margin-bottom: 0;
  font-size: var(--defaultFontSize);
  color: var(--access-menu-link-color);
  text-decoration: none;
  /* Override Popover provided font family */
  font-family: var(--defaultFontFamily);
}

.flight-list-table-action-menu-item:hover {
  cursor: pointer;
  background-color: var(--primary-hover-color);
  color: var(--page-container-text);
  text-decoration: none;
}

.popover-menu-header {
  margin-top: 0.3rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--flight-action-menu-subheader-color);
  padding: 0 1rem;
}

.menu-seperator-line {
  margin-top: 0.5rem;
  height: 0.16rem;
  background-color: var(--menu-separator-line-color);
}

.external-link-icon {
  width: 1.1rem;
  height: 0.9rem;
  color: var(--primary-icon-color);
  margin-bottom: 0.6rem;
  margin-left: 0.2rem;
}

/* React Bootstrap popover arrow color */
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: var(--flight-action-menu-arrow-color);
}

.menu-action-ellipsis {
  color: var(--interaction-link-color);
}

.flight-puck.tailbreak-flight .menu-action-ellipsis,
.classic-flight-puck.tailbreak-flight .menu-action-ellipsis {
  color: #000000;
}

.flight-puck .menu-action-ellipsis,
.flight-puck.phase-out .menu-action-ellipsis,
.flight-puck.phase-on .menu-action-ellipsis,
.flight-puck.phase-in .menu-action-ellipsis,
.flight-puck.phase-off .menu-action-ellipsis,
.flight-puck.swap-flight-puck .menu-action-ellipsis,
.flight-puck.cancelled .menu-action-ellipsis,
.flight-puck.watch-flight .menu-action-ellipsis,
.flight-puck .menu-action-ellipsis,
.classic-flight-puck.phase-out .menu-action-ellipsis,
.classic-flight-puck.phase-on .menu-action-ellipsis,
.classic-flight-puck.phase-in .menu-action-ellipsis,
.classic-flight-puck.phase-off .menu-action-ellipsis,
.classic-flight-puck.swap-flight-puck .menu-action-ellipsis,
.classic-flight-puck.cancelled .menu-action-ellipsis,
.classic-flight-puck.watch-flight .menu-action-ellipsis,
.classic-flight-puck .menu-action-ellipsis {
  color: #ffffff;
}

.menu-action-ellipsis:hover {
  cursor: pointer;
}
