.flex-container-row {
  display: flex;
  flex-direction: row;
}
.vert-margin-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.vert-margin-auto.arrow-right {
  transform: translateY(60%);
}
.div-arrowright-centered {
  color: var(--gray);
  margin-left: 0.25rem;
  margin-right: 0.2rem;
}
.addflight-text {
  font-size: var(--defaultFontSize);
}
.MuiPopover-root {
  z-index: 1700 !important;
}

.addflight-modal .MuiOutlinedInput-notchedOutline {
  border: none;
}
.addflight-modal .station-local-label {
  float: right;
}

.addflight-modal input.airport-code-input {
  height: 4.4rem;
  border: 2px solid var(--lightGray);
}

.addflight-modal .form-control.wt-resize {
  height: 4.4rem !important;
}
.marginBZero {
  margin-bottom: 0px !important;
}
.addflight-modal .MuiOutlinedInput-adornedStart {
  padding-left: 1.2rem !important;
}
.addflight-modal .MuiInputAdornment-positionStart {
  margin-right: 0.6rem;
}
.addflight-modal .station-control {
  width: 1.5rem;
}
.collapse-chevron {
  color: var(--primary-icon-color);
  float: right;
  height: 1.6rem !important;
  width: 1.6rem !important;
  cursor: pointer;
  margin-top: 0.3rem;
}

/* This is for the airport code fields */
.addflight-modal .form-control:focus {
  color: var(--page-container-text);
  background-color: transparent;
  border: 2px solid var(--secondary-border-color);
  outline: 0;
  box-shadow: none;
}
