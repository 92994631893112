.flights-container.swap-mode-active .flights-gantt-footer {
  display: flex;
  justify-content: space-between;
  position: fixed;
  overflow: hidden;
  background: var(--flight-nav-bar-background);
  border-top: 0.6rem solid var(--gantt-flight-toggle-selected-background);
  border-bottom: 0.6rem solid var(--gantt-flight-toggle-selected-background);
  height: 6.8rem;
  width: calc(100vw - 1rem);
  bottom: 0;
  margin: 0.5rem;
  z-index: 1000;
}

.swap-mode-active.summary-panel-mini .flights-gantt-footer {
  bottom: var(--constant-gantt-bottom-summary-mini-height);
}

.swap-mode-active.summary-panel-expanded .flights-gantt-footer {
  bottom: var(--constant-gantt-bottom-summary-expanded-height);
}

.swap-mode-active .swap-footer-cancel {
  font-size: 2.2rem;
  color: var(--gray);
  cursor: pointer;
  position: absolute;
}

.swap-mode-active .swap-footer-cancel:hover {
  color: var(--secondary-hover-color);
}

.swap-mode-active .swap-footer-cancel-container {
  display: flex;
  padding-top: 2rem;
  padding-right: 1.6rem;
  padding-bottom: 2.8rem;
  padding-left: 2.4rem;
}

.footer-buttons{
  display: flex;
}
.undo-redo-button-container{
  display: flex;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 10rem;
  height: 3rem;
  box-shadow: var(--flight-nav-bar-item-shadow);
}
.undo-redo-section{
  display: flex;
  margin-top: 0.9rem;
  justify-content: space-between;
  width: 25rem;
  margin-right: 1.5rem;
}

.undo-redo-button{
  color: var(--primary-button-background);
  background-color: transparent;
}

.button-secondary:disabled
{
  background-color: transparent;
}

.vertical-line{
  font-weight: bold;
  height:80%;
  width:1px;
  background-color:white;
  opacity: 50%;
  margin-top: 2px;
}

.swap-footer-button-container {
  display: flex;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 10rem;
  height: 3rem;
  box-shadow: var(--flight-nav-bar-item-shadow);
  background-color: var(--flight-nav-bar-icon-background);
  margin-top: 0.9rem;
  margin-right: 1rem;
}

.inherit-background-color{
  background-color: inherit;
}

/* for small screens */
@media (max-width: 992px) {
  .swap-mode-active.summary-panel-expanded .flights-gantt-footer {
    bottom: var(--constant-gantt-bottom-summary-expanded-height-portrait);
  }
}
