.activity-log-detail-time {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.activity-log-detail-tag {
  font-weight: 900;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--flight-panel-secondary-label);
  padding-top: 1.4rem;
}
.activity-log-detail-note {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-top: 1.4rem;
}
.activity-log-detail {
  border-bottom: 0.15rem solid var(--primary-border-color);
  padding-bottom: 2.2rem;
  padding-top: 2.2rem;
}
