.deadheads-table {
  background-color: black !important;
}

.deadheads-table table tr td:first-child {
  font-weight: bold;
}

.deadheads-table table tr td:nth-child(3) {
  padding: 0 8px;
}
