.user-icon {
  font-size: 2.6rem;
  color: var(--primary-icon-color);
}

.user-icon:hover {
  cursor: pointer;
}

.user-menu-dropdown-list {
  font-family: var(--defaultFontFamily);
  /* Override Bootstrap default font size */
  font-size: var(--defaultFontSize);
  font-weight: normal;
}

/* override bootstrap dropdown-menu class */
.user-menu .dropdown-menu {
  box-shadow: var(--flight-action-menu-box-shadow);
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 16rem;
  background-color: var(--dropdown-menu-background);
}

.dropdown-item:active {
  color: #000000;
  background-color: inherit;
}
.user-menu a {
  color: var(--page-nav-text);
}
/* main menu related */
.menu-header {
  border-bottom: 2px solid var(--menu-separator-line-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-header span,
.menu-header svg {
  display: block;
}

.dropDownItem {
  color: var(--page-nav-text);
  padding: 0.8rem 1.2rem;
}

/* Sign out link */
a.dropDownItem {
  display: block;
}

/* sets the gray background on hover */
a.dropDownItem:hover,
.menu-header:hover,
.noPaddingHeader:hover,
.subMenuItem.dropdown-item:hover {
  color: var(--page-nav-text);
  text-decoration: none;
  cursor: pointer;
  background-color: var(--primary-hover-color);
}

/* submenu related (theme settings) */
.subMenu {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.subMenu a {
  color: var(--page-nav-text);
}

.subMenuItem.dropdown-item {
  display: flex;
  padding: 0.4rem 0 0.4rem 0;
}

/* holding mouse down on an item */
.subMenuItem.dropdown-item.active,
.subMenuItem.dropdown-item:active {
  color: var(--page-nav-text);
  text-decoration: none;
  background-color: inherit;
}

.check-mark-container,
.submenu-back-icon-container {
  flex-basis: 4rem;
}

.noPaddingHeader {
  color: var(--page-nav-text);
  display: flex;
  font-weight: 600;
  border-bottom: 2px solid var(--menu-separator-line-color);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.check-mark-container .faCheck {
  color: var(--primary-icon-color);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 100%;
}

.check-mark-container svg {
  width: 1em;
  height: 1em;
}

.faCaret {
  vertical-align: middle;
  color: var(--primary-icon-color);
}

.arrowWithCircle {
  margin-right: auto;
  margin-left: auto;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  display: block;
}

/* CSS for transitions */
.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5000ms linear 5000ms;
}

/* exit */
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 5000ms linear;
}
.fade-exit-done {
  opacity: 0;
}

/* slide enter */
.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateY(0.5rem);
  z-index: 1;
}
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition:
    opacity 3000ms linear 1000ms,
    transform 3000ms ease-in-out 1000ms;
}

/* slide exit */
.slide-exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}
.slide-exit.slide-exit-active {
  opacity: 0;
  transform: scale(0.97) translateY(0.5rem);
  transition:
    opacity 1500ms linear,
    transform 1500ms ease-out;
}
.slide-exit-done {
  opacity: 0;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
.dropDownItem.disabled {
  color: var(--gray);
  pointer-events: none;
  opacity: 0.5;
}
