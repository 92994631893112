.user-form-container {
  display: block;
}

.user-roles {
  margin-left: 0px;
}

.user-info {
  display: block;
}

.user-form-container .text-field {
  margin-bottom: 1.6rem;
}

.text-field .MuiInputBase-root {
  width: 100%;
}
/* material ui overrides */

/* form label colors on focus */
.user-form-container .MuiFormLabel-root.Mui-focused {
  color: #656565;
}

/* text field underline color on focus, */
.user-form-container .MuiInput-underline:after {
  border-bottom-color: #656565;
}

/* checkbox color */
.user-form-container .MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--primary-icon-color);
}
