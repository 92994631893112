/* general */
.flex-row {
  display: flex;
  justify-content: space-between;
}

.flex-row-grow {
  display: flex;
  justify-content: space-evenly;
}

.flex-row-fill {
  display: flex;
  justify-content: space-around;
}

.flex-row-left {
  display: flex;
  justify-content: flex-start;
}

.flex-col-left {
  flex: 1;
  flex-basis: 0;
}

.flex-col-right {
  flex: 1;
  flex-basis: 0;
}
.flex-table-item {
  display: flex;
  flex-basis: 0;
}

.flex-row-item-fill {
  flex-grow: 1;
}

.flight-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flight-header {
  font-weight: 800;
  font-size: 1.6rem;
  vertical-align: middle;
}

.flight-header-span {
  vertical-align: middle;
}
.flight-header-right {
  font-weight: normal;
  font-size: 1.6rem;
  align-items: flex-end;
  margin-right: 2.4rem;
}

.flight-header-left {
  display: flex;
}
.flight-zulu-offset {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
  margin-right: 2.4rem;
}
.section-separator {
  margin-top: 1.5rem;
  height: 0.1rem;
  border-bottom: 1.5px solid var(--primary-border-color);
  margin-bottom: 1.5rem;
}
.item-label {
  font-weight: bold;
  margin-top: 1.4rem;
  padding-top: 0.1rem;
  padding-left: 0.1rem;
  padding-right: 0.5rem;
  padding-bottom: 0.1rem;
}

.item-label-horizontal, .item-value-horizontal {
  float:left;
  margin-top: 0.4rem;
  padding-top: 0.1rem;
  padding-left: 0.1rem;
  padding-right: 0.5rem;
  padding-bottom: 0.1rem;
  text-wrap: nowrap;
}
.item-label-horizontal {
  font-weight: bold;
}
.float-left {
  float: left;
}
.flight-pane-content-tab a.info-link-item,
a.info-link-item > .external-link-icon {
  color: var(--interaction-link-color);
  font-weight: 400;
}

.title-bar-half {
  font-weight: bold;
  background-color: lightgray;
  padding: 0.1rem;
  width: 50%;
  display: inline-block;
}

.title-station {
  display: inline-block;
}

/* basic item that needs space around it. Ex. Dates and times on the same line */
.basic-cell {
  padding-left: 0px;
  padding-right: 0.3rem;
  display: inline-block;
}

.item-cell {
  padding-left: 0px;
  padding-right: 0px;
  display: inline-block;
}

.basic-cell-left {
  padding-right: 0.3rem;
  display: inline-block;
}

.wide-cell-left {
  padding-right: 2rem;
  display: inline-block;
}

.wide-cell {
  padding-left: 2rem;
  padding-right: 2rem;
  display: inline-block;
}

/* FlightTabHeader */
#flight-tab-header-container {
  padding-right: 2.4rem;
}

.flight-tab-component {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* FlightTabStatus */
.irrops-msg {
  background: rgb(222, 117, 12);
  border-radius: 0.3rem;
  font-weight: 900;
  color: white;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

/* FlightTabValueIndex */
.scores-table,
.scores-table-row,
.scores-table-item {
  padding: 0.1rem;
}

/* FlightTabTimeSchedule */
.schedule-table,
.schedule-table-row {
  padding: 0px;
}

.schedule-table > th,
.schedule-table > td {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.section-sub-header {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  text-decoration: underline;
  font-weight: 800;
}

.schedule-table-item {
  padding: 0px;
}
.schedule-table-label {
  font-weight: bold;
}
.schedule-table {
  width: 100%;
}

.flight-tab-container .secondary-label {
  color: var(--secondary-text-color);
  font-weight: 600;
  font-size: 1.1rem;
}

.flight-tab-summary {
  padding-top: 1rem;
}
