.confirmation-config-dialog .modal-v2-footer-container.multi-buttons .modal-v2-footer-buttons,
.confirmation-config-dialog .modal-v2-footer-container.multi-buttons.modal-footer,
.dialog-actions-buttons {
  align-content: center;
}

.confirmation-config-dialog .modal-v2-footer-container.multi-buttons .confirmation-config-dialog-footer-button.clear .button,
.dialog-actions-buttons .confirmation-config-dialog-footer-button.clear .button {
  border: none;
  background-color: transparent;
  color: var(--interaction-link-color);
}

.dialog-actions-buttons .confirmation-config-dialog-footer-button.clear .button {
  width: 3.4rem;
  height: 1.7rem;
  font-size: 1.4rem;
}

.dialog.confirmation-config-dialog .MuiDialog-paperWidthSm {
  width: 47rem;
  /* height: 32.1rem; */
}
.dialog-actions-buttons .confirmation-config-dialog-footer-button.apply:focus,
.confirmation-config-dialog .modal-v2-footer-container.multi-buttons .confirmation-config-dialog-footer-button.clear .button:focus {
  outline: 0.1rem solid var(--interaction-link-color);
}
.dialog-actions-buttons .confirmation-config-dialog-footer-button.apply .button {
  width: 12.4rem;
  height: 4.8rem;
}

.MuiDialog-root.dialog.confirmation-config-dialog .dialog-actions-buttons {
  padding-bottom: 2.4rem;
  display: flex;
  align-items: center;
  gap: 0.1rem;
  flex-direction: row;
}

.dialog-hr-top,
.dialog .dialog-hr-bottom {
  border-color: var(--secondary-border-color);
}

.confirmation-config-footer-button {
  margin-left: 0.5rem;
  margin-right:0.5rem;
  font-size: 1.6rem;
}
.confirmation-config-dialog .confirmation-config-dialog-footer-button.cancel,
.confirmation-config-dialog .confirmation-config-footer-button.cancel {
  background: none;
  color: var(--lightBlue);
  border-color: var(--lightBlue);
}
.confirmation-config-dialog .textinput {
  border: 1px solid var(--gray);
}
.confirmation-config-dialog .form-horizontal-rule.dialog-hr-top {
  border-top: 1px solid var(--gray);
}

.confirmation-config-message {
  display: flex;
}
.confirmation-config-message .alert-exclamationmark-icon {
  margin-right: 1.6rem;
}