.add-view-icon{
  color: var(--flight-nav-bar-icon-fill);
  background-color: var(--flight-nav-bar-icon-background);
}

.add-view-button-container {
    border-radius: 0.6rem;
    cursor: pointer;
    width: 3.4rem;
    height: 3rem;
    box-shadow: var(--flight-nav-bar-item-shadow);
    display: flex;
    margin-left: 0.6rem;
    align-items: center;
    justify-content: center;
    background-color: var(--flight-nav-bar-icon-background);
  }

  .add-view-button-container.disabled {
    pointer-events: none;
  }

