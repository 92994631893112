.textareafield {
  font-family: var(--defaultFontFamily);
}
.textareafield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--secondary-border-color);
}
.textareafield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--secondary-hover-color);
}
.textareafield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--secondary-hover-color);
}
