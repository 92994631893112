.time-diff-label-red {
  color: var(--delay-indicator-color);
}

.time-diff-label-green {
  color: var(--early-indicator-color);
}

span.time-diff-label-green::before,
span.time-diff-label-red:before {
  content: '\00a0';
}
