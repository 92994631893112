.toolbar {
  background-color: var(--view-window-toolbar-background-color);
  height: 3rem;
  width: 100%;
  padding: 3px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.multiview-toolbar {
  cursor:move;
}

.toolbar-left-container,
.toolbar-right-container {
  display: flex;
  align-items: center;
}

.toolbar-left-container {
  width: calc(100% - 80px);
}

#toolbar-drag-area {
  align-items: center;
  display: flex;
  padding: 0;
  height: 100%;
  width:  calc(100% - 30px);
  justify-content: flex-start;
}

.toolbar-icon-container {
  display: flex;
  align-items: center;
}

.toolbar .toolbar-icon {
  margin-left: 6px;
  margin-right: 6px;
  color: var(--flight-nav-bar-icon-fill);
  background: transparent;
  unicode-bidi: isolate;
  padding: unset;
  border: none;
  cursor: pointer;
}

.toolbar .toolbar-savedconfig-indicator {
  margin-right: 10px;
  color: var(--flight-nav-bar-icon-fill);
}

/* Disabled color for maximize icon when the view window is single and not maximized */
.view-window.single:not(.maximized) .toolbar-icon-container .toolbar-icon.maximize-icon {
  color: var(--flight-nav-bar-icon-disabled-fill);
}

/* Enabled color for maximize icon when it has the enabled class */
.toolbar-icon-container .toolbar-icon.maximize-icon.enabled,
.toolbar-icon-container .toolbar-icon.restore-window-icon {
  color: var(--flight-nav-bar-icon-fill);
}

.toolbar.swap-mode-toolbar .toolbar-icon {
  margin-left: 0px;
}

.toolbar .toolbar-title {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.toolbar-title.minimized {
  font-size: 10.5px; /* Adjust the font size as needed */
}

.toolbar.swap-mode-toolbar {
  border-left: 0.6rem solid var(--gantt-flight-toggle-selected-background);
  border-right: 0.6rem solid var(--gantt-flight-toggle-selected-background);
}

.toolbar .minimize-icon.disabled {
  color: var(--flight-nav-bar-icon-disabled-fill);
  cursor: pointer;
}

.view-window.minimized .toolbar .maximize-icon.enabled {
  color: var(--flight-nav-bar-icon-fill);
}