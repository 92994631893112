.irrops-flight-modal input.airport-code-input {
  height: 4.4rem;
  border: 2px solid var(--primary-border-color);
}

.irrops-flight-modal input.airport-code-input:focus {
  height: 4.4rem;
  border: 2px solid var(--secondary-border-color);
}
.irrops-hz-rule {
  margin-bottom: 1.9rem;
}
.minsinput-container {
  position: relative;
}

.stationlabelinput-container {
  position: relative;
}

.MuiDialog-root.dialog .MuiFormControlLabel-root .MuiSvgIcon-root {
  font-size: 1.8rem;
  color: var(--primary-icon-color);
}

.MuiDialog-root.dialog .MuiFormControlLabel-root .MuiCheckbox-colorSecondary:hover,
.MuiDialog-root.dialog .MuiFormControlLabel-root .MuiCheckbox-colorSecondary.Mui-checked {
  background-color: transparent;
  color: transparent;
}

/* for large screens */
@media (min-width: 993px) {
  .irrops-flight-modal {
    width: 880px;
  }
  .irrops-station-addcontinuation-check {
    position: relative;
  }
  .irrops-station-addcontinuation-check .MuiFormControlLabel-root {
    position: absolute;
    margin-bottom: auto;
    bottom: 1.6rem;
  }
  .irrops-hz-rule {
    margin-top: 3rem !important;
  }
}

/* for small screens */
@media (max-width: 992px) {
  .irrops-flight-modal {
    width: 464px;
  }
  .irrops-hz-rule {
    margin-top: 2.1rem !important;
  }
}
