.dashboard {
  padding: 4rem 1.6rem 0 1.6rem;
}

.dashboard span {
  color: var(--page-container-text);
  font-size: 3.2rem;
  text-align: center;
  display: block;
}

.airtrAK-logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.airtrAK-logo path {
  fill: var(--page-container-text);
}
