.crosshair-hor-line-wrapper {
  width: 100vw;
  height: 1.4rem;
  position: fixed;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.crosshair-hor-line {
  width: 100vw;
  height: 0.2rem;
  background: repeating-linear-gradient(90deg, #fff 0 5px, #0000 0 7px);
  position: fixed;
  z-index: 11;
}

.crosshair-ver-line-wrapper {
  width: 1.4rem;
  height: 100vh;
  position: absolute;
  z-index: 9;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.crosshair-ver-line {
  width: 0.2rem;
  height: 100vh;
  background: repeating-linear-gradient(0deg, #fff 0 5px, #0000 0 7px);
  position: absolute;
  z-index: 9;
  overflow: hidden;
  min-height: 100%;
}

.crosshair-hor-line.active-crosshair {
  height: 0.3rem;
  background: repeating-linear-gradient(90deg, #fff 0 10px, #0000 0 13px);
}

.crosshair-ver-line.active-crosshair {
  width: 0.3rem;
  background: repeating-linear-gradient(0deg, #fff 0 10px, #0000 0 13px);
}
