.gantt-scale-xlarge {
  --puck-height: 1.8rem; /* (19px / 10px) rem */
  --puck-margin-top: 1.3rem; /* 2px */
  --puck-line-height: 1.8rem; /* 18px */
  --puck-line-height-selected: 1.68rem; /* mods to try to make flight number centered */
  --puck-container-height: 2.56rem; /* --puck-height + 0.66rem */
  --puck-border-radius: 2px; /* Note: Border radius is affected by puck size */
  --gantt-row-height: 4.8rem; /* --puck-container-height + 2px */
  --gantt-row-line-height: 4.8rem; /* --puck-container-height + 2px */
  --gantt-row-line-weight: 1.5px; /* 2px */
  --aircraft-label-height: 2.72rem; /* --gantt-row-height - 0.15rem */
  --aircraft-label-font-size: 1.4rem; /* 14px */
  --aircraft-label-icon-container-width: 1.6rem; /* 16px */
  --aircraft-container-height: 4.2rem; /* 42px */
  --aircraft-container-width: 9.6rem; /* 96px */
  --aircraft-container-padding: 0 1rem; /* 7px */
  --aircraft-container-width-with-indicators: 15.6rem; /* 156px */
  --aircraft-label-width-with-indicators: 4.3rem;
  --flight-number-font-size: 1.4rem; /* 14px */
  --ground-station-font-size: 1.4rem; /* 14px */
  --selected-puck-early-delay-indicator-height: 1.6rem; /* 16px (rest 2px of puck is used by border) */
  --flight-action-menu-top: 0.2rem; /* 0.2px */
  --flight-action-menu-bottom: 0.2rem; /* 0.2px */
  --flight-action-menu-height: 1.4rem; /* 14px */
  --flight-action-menu-width: 2.8rem; /* 28px */
  --flight-action-menu-padding: 0.6rem; /* 6px */
  --flight-action-menu-icon-size: 1.6rem; /* 16px */
  --puck-early-dealy-indicator-border: 1px; /* 1px */
  --select-puck-border-padding: 1.2rem; /* 12px */
  --select-short-puck-border-padding: 1.6rem; /* 16px */
  --select-puck-border-left: -1.4rem; /* -1.4px */
  --select-short-puck-border-left: -1.8rem; /* -18px */
  --select-puck-border-height: 4.5rem; /* 45px */
  --select-puck-early-arrival-left: -2.8rem; /* -28px */
}

.gantt-scale-large {
  --puck-height: 1.6rem; /* (19px / 10px) rem */
  --puck-margin-top: 0.5rem; /* 2px */
  --puck-line-height: 1.6rem; /* 16px */
  --puck-line-height-selected: 1.68rem; /* mods to try to make flight number centered */
  --puck-container-height: 2.26rem; /* --puck-height + 0.66rem */
  --puck-border-radius: 2px; /* Note: Border radius is affected by puck size */
  --gantt-row-height: 3.6rem; /* --puck-container-height + 2px */
  --gantt-row-line-height: 3.6rem; /* --puck-container-height + 2px */
  --gantt-row-line-weight: 1.5px; /* 2px */
  --aircraft-label-height: 2.72rem; /* --gantt-row-height - 0.15rem */
  --aircraft-label-font-size: 1.2rem; /* 12px */
  --aircraft-label-icon-container-width: 1.4rem; /* 10px */
  --aircraft-container-height: 3.6rem; /* 36px */
  --aircraft-container-width: 7.6rem; /*  76px */
  --aircraft-container-padding: 0 0.9rem; /* 7px */
  --aircraft-container-width-with-indicators: 13.6rem; /*  136px */
  --aircraft-label-width-with-indicators: 3.7rem;
  --flight-number-font-size: 1.2rem; /* 12px */
  --ground-station-font-size: 1.2rem; /* 12px */
  --selected-puck-early-delay-indicator-height: 1.6rem; /* 16px (rest 2px of puck is used by border) */
  --flight-action-menu-top: 0.22rem; /* 2.2px */
  --flight-action-menu-bottom: 0.22rem; /* 2.2px */
  --flight-action-menu-height: 1.2rem; /* 12px */
  --flight-action-menu-width: 2.4rem; /* 24px */
  --flight-action-menu-padding: 0.4rem; /* 4px */
  --flight-action-menu-icon-size: 1.6rem; /* 16px */
  --puck-early-dealy-indicator-border: 1px; /* 1px */
  --select-puck-border-padding: 0.75rem; /* 7.5px */
  --select-short-puck-border-padding: 0.75rem; /* 7.5px */
  --select-puck-border-left: -0.95rem; /* -9.5px */
  --select-short-puck-border-left: -0.95rem; /* 9.5px */
  --select-puck-border-height: 3.3rem; /* 30.3px */
  --select-puck-early-arrival-left: -2rem; /* -20px */
}

.gantt-scale-medium {
  --puck-height: 1.4rem; /* (19px / 10px) rem */
  --puck-margin-top: 0.2rem; /* 2px */
  --puck-line-height: 1.4rem; /* 14px  */
  --puck-line-height-selected: 1.4rem; /* mods to try to make flight number centered */
  --puck-container-height: 2.06rem; /* --puck-height + 0.66rem */
  --puck-border-radius: 2px; /* Note: Border radius is affected by puck size */
  --gantt-row-height: 2.8rem; /* --puck-container-height + 2px */
  --gantt-row-line-height: 2.8rem; /* --puck-container-height + 2px */
  --gantt-row-line-weight: 1.5px; /* 2px */
  --aircraft-label-height: 2.65rem; /* --gantt-row-height - 0.15rem */
  --aircraft-label-font-size: 1.2rem; /* 12px */
  --aircraft-label-icon-container-width: 1.4rem; /* 10px */
  --aircraft-container-height: 2.8rem; /* 28px */
  --aircraft-container-width: 7rem; /* 70px */
  --aircraft-container-padding: 0 0.9rem; /* 7px */
  --aircraft-container-width-with-indicators: 13rem; /*  130px */
  --aircraft-label-width-with-indicators: 3.9rem;
  --flight-number-font-size: 1.2rem; /* 12px */
  --ground-station-font-size: 1.2rem; /* 12px */
  --selected-puck-early-delay-indicator-height: 1.4rem; /* 14px (rest 2px of puck is used by border) */
  --flight-action-menu-top: 0.22rem; /* 2.2px */
  --flight-action-menu-bottom: 0.2rem; /* 2px */
  --flight-action-menu-height: 1rem; /* 10px */
  --flight-action-menu-width: 2rem; /* 20px */
  --flight-action-menu-padding: 0.2rem; /* 2px */
  --flight-action-menu-icon-size: 1.6rem; /* 16px */
  --puck-early-dealy-indicator-border: 1px; /* 1px */
  --select-puck-border-padding: 0.35rem; /* 3.5px */
  --select-short-puck-border-padding: 0.5rem; /* 5px */
  --select-puck-border-left: -0.55rem; /* -5.5px */
  --select-short-puck-border-left: -0.7rem; /* -7px */
  --select-puck-border-height: 2.55rem; /* 25.5px */
  --select-puck-early-arrival-left: -1.2rem; /* -10.2px */
}

.gantt-scale-small {
  --puck-height: 1.2rem; /* (19px / 10px) rem */
  --puck-margin-top: 0.1rem; /* 2px */
  --puck-line-height: 1.2rem; /* 12px */
  --puck-line-height-selected: 1.2rem; /* mods to try to make flight number centered */
  --puck-container-height: 2rem; /* --puck-height + 0.66rem */
  --puck-border-radius: 2px; /* Note: Border radius is affected by puck size */
  --gantt-row-height: 2.2rem; /* --puck-container-height + 2px */
  --gantt-row-line-height: 2.2rem; /* --puck-container-height + 2px */
  --gantt-row-line-weight: 1px; /* 2px */
  --aircraft-label-height: 1.75rem; /* --gantt-row-height - 0.15rem */
  --aircraft-label-font-size: 1rem; /* sync aircraft,flight & gound station font sizes */
  --aircraft-label-icon-container-width: 1.2rem; /* 12px */
  --aircraft-container-height: 2rem; /* 20px */
  --aircraft-container-width: 6rem; /* 60px */
  --aircraft-container-padding: 0 0.8rem; /* 7px */
  --aircraft-container-width-with-indicators: 12rem; /*  120px */
  --aircraft-label-width-with-indicators: 3.7rem;
  --flight-number-font-size: 1rem; /* 10px */
  --ground-station-font-size: 1rem; /* sync aircraft,flight & gound station font sizes */
  --selected-puck-early-delay-indicator-height: 1.2rem; /* 12px (rest 2px of puck is used by border) */
  --flight-action-menu-top: 0.2rem; /* 0.2px */
  --flight-action-menu-bottom: 0.19rem; /* 0.2px */
  --flight-action-menu-height: 0.8rem; /* 8px */
  --flight-action-menu-width: 1.8rem; /* 20px */
  --flight-action-menu-padding: 0.2rem; /* 1px */
  --flight-action-menu-icon-size: 1.4rem; /* 14px */
  --puck-early-dealy-indicator-border: 0.5px; /* 5px */
  --select-puck-border-padding: 0.22rem; /* 2.2px */
  --select-short-puck-border-padding: 0.4rem; /* 4px */
  --select-puck-border-left: -0.4rem; /* -4px */
  --select-short-puck-border-left: -0.62rem; /* -6.2px */
  --select-puck-border-height: 2rem; /* 20px */
  --select-puck-early-arrival-left: -0.8rem; /* -8px */
}

.gantt-scale-xsmall {
  --puck-height: 1rem; /* (19px / 10px) rem */
  --puck-line-height: 1rem; /* 10px */
  --puck-line-height-selected: 1rem; /* mods to try to make flight number centered */
  --puck-container-height: 1.65rem; /* --puck-height + 0.66rem */
  --puck-border-radius: 2px; /* Note: Border radius is affected by puck size */
  --gantt-row-height: 1.7rem; /* --puck-container-height + 2px */
  --gantt-row-line-height: 1.6rem; /* --puck-container-height + 2px */
  --gantt-row-line-weight: 1px; /* 2px */
  --aircraft-label-height: 1.45rem; /* --gantt-row-height - 0.15rem */
  --aircraft-label-font-size: 0.8rem; /* sync aircraft,flight & gound station font sizes */
  --aircraft-label-icon-container-width: 1rem; /* 10px */
  --aircraft-container-height: 1.6rem; /* 42px */
  --aircraft-container-width: 4rem; /* 40px */
  --aircraft-container-padding: 0 0.7rem; /* 7px */
  --aircraft-container-width-with-indicators: 10rem; /*  100px */
  --aircraft-label-width-with-indicators: 3.1rem;
  --flight-number-font-size: 0.8rem; /* 8px */
  --ground-station-font-size: 0.8rem; /* 8px */
  --selected-puck-early-delay-indicator-height: 1rem; /* 10px (rest 2px of puck is used by border) */
  --flight-action-menu-top: 0.16rem; /* 1.6px */
  --flight-action-menu-bottom: 0.16rem; /* 1.6px */
  --flight-action-menu-padding: 0.2rem; /* 2px */
  --flight-action-menu-height: 0.7rem; /* 7px */
  --flight-action-menu-width: 1.8rem; /* 18px */
  --flight-action-menu-icon-size: 1.4rem; /* 14px */
  --puck-early-dealy-indicator-border: 0.5px; /* 0.5px */
  --select-puck-border-padding: 0.12rem; /* 1.2px */
  --select-short-puck-border-padding: 0.2rem; /* 2px */
  --select-puck-border-left: -0.3rem; /* -3px */
  --select-short-puck-border-left: -0.4rem; /* -4px */
  --select-puck-border-height: 1.6rem; /* 16px */
  --select-puck-early-arrival-left: -0.4rem; /* -8px */
}

.gantt-scale-xxsmall {
  --puck-height: 0.8rem; /* Adjusted down from 1rem */
  --puck-margin-top: 0.05rem; /* Slightly smaller margin */
  --puck-line-height: 0.8rem; /* Adjusted down */
  --puck-line-height-selected: 0.8rem; /* Consistent with line height */
  --puck-container-height: 1.4rem; /* --puck-height + 0.66rem */
  --puck-border-radius: 2px; /* Consistent with other scales */
  --gantt-row-height: 1.5rem; /* --puck-container-height + 2px */
  --gantt-row-line-height: 1.5rem; /* --puck-container-height + 2px */
  --gantt-row-line-weight: 0.8px; /* Slightly lighter */
  --aircraft-label-height: 1.2rem; /* --gantt-row-height - 0.15rem */
  --aircraft-label-font-size: 0.7rem; /* Smaller font size */
  --aircraft-label-icon-container-width: 0.8rem; /* Adjusted down */
  --aircraft-container-height: 1.4rem; /* Adjusted down */
  --aircraft-container-width: 3.5rem; /* Smaller width */
  --aircraft-container-padding: 0 0.5rem; /* Adjusted padding */
  --aircraft-container-width-with-indicators: 8rem; /* Adjusted down */
  --aircraft-label-width-with-indicators: 2.5rem; /* Adjusted down */
  --flight-number-font-size: 0.6rem; /* Smaller font size */
  --ground-station-font-size: 0.7rem; /* Smaller font size */
  --selected-puck-early-delay-indicator-height: 0.8rem; /* Adjusted down */
  --flight-action-menu-top: 0.1rem; /* Smaller top margin */
  --flight-action-menu-bottom: 0.1rem; /* Smaller bottom margin */
  --flight-action-menu-height: 0.5rem; /* Smaller height */
  --flight-action-menu-width: 1.5rem; /* Adjusted down */
  --flight-action-menu-padding: 0.1rem; /* Smaller padding */
  --flight-action-menu-icon-size: 1.2rem; /* Smaller icon size */
  --puck-early-dealy-indicator-border: 0.3px; /* Adjusted down */
  --select-puck-border-padding: 0.1rem; /* Smaller padding */
  --select-short-puck-border-padding: 0.2rem; /* Adjusted down */
  --select-puck-border-left: -0.2rem; /* Adjusted down */
  --select-short-puck-border-left: -0.3rem; /* Adjusted down */
  --select-puck-border-height: 1.4rem; /* Adjusted down */
  --select-puck-early-arrival-left: -0.3rem; /* Adjusted down */
}

.gantt-scale-xxxsmall {
  --puck-height: 0.6rem; /* Adjusted down from 0.8rem */
  --puck-margin-top: 0.04rem; /* Slightly smaller margin */
  --puck-line-height: 0.6rem; /* Adjusted down */
  --puck-line-height-selected: 0.6rem; /* Consistent with line height */
  --puck-container-height: 1.1rem; /* --puck-height + 0.5rem */
  --puck-border-radius: 1.5px; /* Consistent with other scales */
  --gantt-row-height: 1.2rem; /* --puck-container-height + 0.1rem */
  --gantt-row-line-height: 1.2rem; /* --puck-container-height + 0.1rem */
  --gantt-row-line-weight: 0.6px; /* Slightly lighter */
  --aircraft-label-height: 1rem; /* --gantt-row-height - 0.2rem */
  --aircraft-label-font-size: 0.6rem; /* Smaller font size */
  --aircraft-label-icon-container-width: 0.6rem; /* Adjusted down */
  --aircraft-container-height: 1.1rem; /* Adjusted down */
  --aircraft-container-width: 3rem; /* Smaller width */
  --aircraft-container-padding: 0 0.4rem; /* Adjusted padding */
  --aircraft-container-width-with-indicators: 6rem; /* Adjusted down */
  --aircraft-label-width-with-indicators: 2rem; /* Adjusted down */
  --flight-number-font-size: 0.5rem; /* Smaller font size */
  --ground-station-font-size: 0.6rem; /* Smaller font size */
  --selected-puck-early-delay-indicator-height: 0.6rem; /* Adjusted down */
  --flight-action-menu-top: 0.08rem; /* Smaller top margin */
  --flight-action-menu-bottom: 0.08rem; /* Smaller bottom margin */
  --flight-action-menu-height: 0.4rem; /* Smaller height */
  --flight-action-menu-width: 1.2rem; /* Adjusted down */
  --flight-action-menu-padding: 0.08rem; /* Smaller padding */
  --flight-action-menu-icon-size: 1rem; /* Smaller icon size */
  --puck-early-dealy-indicator-border: 0.2px; /* Adjusted down */
  --select-puck-border-padding: 0.08rem; /* Smaller padding */
  --select-short-puck-border-padding: 0.16rem; /* Adjusted down */
  --select-puck-border-left: -0.16rem; /* Adjusted down */
  --select-short-puck-border-left: -0.24rem; /* Adjusted down */
  --select-puck-border-height: 1.1rem; /* Adjusted down */
  --select-puck-early-arrival-left: -0.24rem; /* Adjusted down */
}