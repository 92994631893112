.flex-container-row,
.Scaling-modal-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center; /* Corrected from 'left' which is not a valid value for align-items */
  -webkit-user-select: none;
    user-select: none;
}

.counter-flex-container-column .flex-container-row {
  flex-direction: row;
  border: 0.2rem solid var(--secondary-border-color); /* Shorthand for border-style, border-width, and border-color */
  border-radius: 0.3rem;
  padding: 1rem 0 0.9rem;
  width: 11.7rem;
  height: 4.4rem;
}

.counter-flex-container-column,
.flex-container-column-aircraft-number {
  flex-shrink: 0;
  flex-direction: column;
}

.flex-container-column-aircraft-number {
  width: 1.2rem;
}

.flex-container-row .scaling-counter {
  height: 1.6rem;
  width: 1.6rem;
  color: var(--interaction-link-color);
  flex-shrink: 0;
}

.Scaling-modal-row {
  justify-content: space-between;
}

.flex-container-column .text-container {
  font-weight: 700;
  font-family: var(--defaultFontFamily);
  font-style: normal;
  font-size: 1.4rem;
}

.counter-flex-container-column .text-box {
  font-weight: 400;
  font-family: var(--defaultFontFamily);
  font-style: normal;
  font-size: 1.4rem;
  width: 3.2rem;
  height: 1.7rem;
  background-color: transparent;
  color: var(--page-container-text);
  border-color: transparent;
  text-align: center;
}
.scaling-counter-true {
  color: var(--secondary-border-color);
}
.scaling-counter-true:hover,
.scaling-counter-false:hover {
  cursor: pointer;
}

.counter-flex-container-column .text-box:focus{
  outline: 0.1rem solid var(--interaction-link-color);
}

.counter-flex-container-column .divider-line {
  width: 0;
  height: 2rem;
  border-left: 0.1rem solid var(--secondary-border-color);
}