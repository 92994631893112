/* Standby Ground Puck */
.ground-standby-puck {
  font-weight: 700;
  text-align: center;
  height: var(--puck-height);
  color: var(--puck-label-color);
  box-shadow: var(--puck-box-shadow);
  line-height: var(--puck-line-height);
  border-radius: var(--puck-border-radius) !important;
  font-size: var(--ground-station-font-size);
  background: var(--ground-puck-standby-background);
}

.ground-standby-puck > .flight-action-menu {
  position: absolute;
  cursor: pointer;
  right: 0;
  font-size: var(--flight-action-menu-icon-size);
  z-index: 99;
  height: var(--flight-action-menu-height);
  width: var(--flight-action-menu-width);
}

.ground-standby-puck > .flight-action-menu.short-puck-flag {
  border-radius: 0.2rem;
  justify-content: center;
}

.ground-standby-puck .flight-action-menu .action-menu {
  position: relative;
  display: flex;
  align-items: center;
  visibility: hidden;
  top: 0;
  height: 100%;
  right: 0.2rem;
}

.ground-standby-puck .flight-action-menu .menu-action-ellipsis {
  height: var(--flight-action-menu-icon-size);
}

.ground-standby-puck:hover .flight-action-menu .action-menu {
  visibility: visible;
}

.ground-standby-puck.selectable:hover {
  cursor: pointer;
}

.flight-puck:hover .flight-action-menu .action-menu {
  visibility: visible;
}

.flight-puck.selectable:hover {
  cursor: pointer;
}

.group-puck-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ground-standby-puck .flight-action-menu .app-insights-tracking-container {
  height: 100%;
}
.group-puck-container > .flight-action-menu {
  position: absolute;
  cursor: pointer;
  right: 0;
  font-size: var(--flight-action-menu-icon-size);
  z-index: 99;
  height: var(--flight-action-menu-height);
  width: var(--flight-action-menu-width);
}

.ground-standby-puck .menu-action-ellipsis {
  color: #ffffff;
}
.standby-access-menu {
  display: flex;
  justify-content: end;
  top: 0;
  z-index: 99;
  position: absolute;
  width: inherit;
  height: 100%;
}
