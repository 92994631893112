.timeline-axis {
  border-bottom: var(--gantt-row-line-weight) solid var(--primary-border-color);
  overflow-x: hidden;
  display: inline-flex;
  height: var(--constant-timeline-axis-height);
  align-items: center;
  font-size: var(--constant-flight-number-font-size);
  vertical-align: top;
}

.time-increment {
  text-align: center;
  flex: 0 0 auto;
  position: relative;
}

.time-increment .hour-text {
  font-weight: bold;
  height: var(--constant-timeline-hour-text-height);
  line-height: var(--constant-timeline-hour-text-line-height);
}

.time-increment .tick-mark {
  width: 0.2rem;
  height: var(--constant-timeline-tick-mark);
  margin-left: auto;
  margin-right: auto;
  background-color: #eeeeee;
  display: block;
}

.time-increment .day-mark-text {
  font-weight: 900;
  /* white-space: nowrap; */
  /* This margin needs to be removed or adjusted when scaling down,
    but I ain't messin with it rn. */
  /* margin-left: .2rem; */
  font-size: var(--constant-timeline-daymark-font-size);
  text-decoration: underline;
  position: absolute;
}
