.activity-log-header {
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-top: 1.6rem;
}
.activity-log-tab-container {
  margin-right: 2.4rem;
  margin-top: -1rem;
  cursor: text;
}
