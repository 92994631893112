.saveconfig-dialog .modal-v2-footer-container.multi-buttons .modal-v2-footer-buttons,
.saveconfig-dialog .modal-v2-footer-container.multi-buttons.modal-footer,
.dialog-actions-buttons {
  align-content: center;
}

.saveconfig-dialog .modal-v2-footer-container.multi-buttons .saveconfig-dialog-footer-button.clear .button,
.dialog-actions-buttons .saveconfig-dialog-footer-button.clear .button {
  border: none;
  background-color: transparent;
  color: var(--interaction-link-color);
}

.dialog-actions-buttons .saveconfig-dialog-footer-button.clear .button {
  width: 3.4rem;
  height: 1.7rem;
  font-size: 1.4rem;
}

.dialog.saveconfig-dialog .MuiDialog-paperWidthSm {
  width: 47rem;
  /* height: 32.1rem; */
}
.dialog-actions-buttons .saveconfig-dialog-footer-button.apply:focus,
.saveconfig-dialog .modal-v2-footer-container.multi-buttons .saveconfig-dialog-footer-button.clear .button:focus {
  outline: 0.1rem solid var(--interaction-link-color);
}
.dialog-actions-buttons .saveconfig-dialog-footer-button.apply .button {
  width: 12.4rem;
  height: 4.8rem;
}

.MuiDialog-root.dialog.saveconfig-dialog .dialog-actions-buttons {
  padding-bottom: 2.4rem;
  display: flex;
  align-items: center;
  gap: 0.1rem;
  flex-direction: row;
}

.dialog-hr-top,
.dialog .dialog-hr-bottom {
  border-color: var(--secondary-border-color);
}

.saveconfig-footer-button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1.6rem;
}
.saveconfig-dialog .saveconfig-dialog-footer-button.cancel,
.saveconfig-dialog .saveconfig-footer-button.cancel {
  background: none;
  color: var(--lightBlue);
  border-color: var(--lightBlue);
}
.saveconfig-dialog .textinput {
  border: 1px solid var(--gray);
}
.saveconfig-dialog .form-horizontal-rule.dialog-hr-top {
  border-top: 1px solid var(--gray);
}

.saveconfig-dialog .side-indicator {
  margin-top: -0.4rem;
}

.saveconfig-dialog .toast {
  top: 2.9rem;
  left: 59%;
}

.saveconfig-dialog .toast button.close{
  border: none;
  background-color: transparent;
  margin-right: 0;
}

.saveconfig-dialog .message-text {
  line-height: 1;
  font-weight: normal !important;
}
