.ground-puck {
  height: var(--puck-height);
  line-height: var(--puck-line-height);
  font-size: var(--ground-station-font-size);
  font-weight: 700;
}

/* Standby Ground Puck */
.ground-puck.standby {
  background-color: var(--ground-puck-standby-background);
  border-radius: var(--puck-border-radius);
  box-shadow: var(--puck-box-shadow);
  color: var(--puck-label-color);
}

.ground-puck.station {
  z-index: 2;
}

.ground-puck-mismatch-stations {
  color: var(--red);
}

.swap-station-puck,
.swap-station-puck .ground-puck {
  user-select: none;
}
