.rename-view-cancel-button {
    color: var(--primary-button-background);
    background-color: transparent;
    margin-right: 0.5rem;
}

.rename-view-apply-button {
    margin-left: 0.5rem;
}

.rename-view-apply-button:disabled {
    color: var(--primary-button-disabled-background);
    background-color: var(--dark-secondary);
}

.rename-view-popup-modal .makeStyles-normalStyle-23  {
    border: 2px solid var(--secondary-border-color);
}

.rename-view-popup .dialog-actions-buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.4rem;
} 

