.timeinput .MuiOutlinedInput-notchedOutline {
  display: none;
}

.timeinput.MuiOutlinedInput-root.Mui-focused {
  border-color: var(--secondary-hover-color);
}

.timeinput-container {
  position: relative;
}

.MuiInputAdornment-root .MuiTypography-colorTextSecondary {
  color: var(--secondary-text-color);
  font-weight: 600;
}
