
.searchinput input {
  padding: 0.7rem 0.4rem 0.6rem 0.4rem;
  border-color:none;
  font-size: 1.4rem;
}
.searchinput .MuiTextField-root {
  width: 26.1rem;
}
.searchinput .MuiInputBase-root {
  background-color: var(--flight-nav-bar-icon-background);
  box-shadow: var(--flight-nav-bar-item-shadow);
  cursor: default;
}
.searchinput .MuiOutlinedInput-notchedOutline {
  border: none;
}
.searchinput {
  margin-left: 1rem;
  margin-right: 0.4rem;
}
.searchinput .MuiOutlinedInput-adornedEnd {
  padding-right:0px;
  border-radius: 0.6rem;
}
.searchinput .MuiInputAdornment-positionEnd{
  background-color: var(--primary-prev-next-navigation-section);
  height: 100%;
  border-radius: 0 0.6rem 0.6rem 0;
}