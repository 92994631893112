
.single-select-dropdown-container .manage-views-dropdown {
  border-radius: 0.6rem;
  border: none !important;
  max-width: 6rem;
  max-height: 3rem;
  box-shadow: var(--flight-nav-bar-item-shadow);
  margin-left: 0.6rem;
  align-items: center;
  justify-content: center;
  background-color: var(--dropdown-menu-background);
  cursor: pointer;
}

.MuiListItem-root.Mui-selected .check-icon, 
.MuiListItem-root.Mui-selected:hover .check-icon {
  display: inline; 
  scale: 0.7; 
}

.MuiSelect-root .check-icon{
  display: none;
}

.available-view {
  display: flex; 
  align-items: center;
  justify-content: space-between; 
  position: relative; 
  background-color: transparent; 
  border: 0px solid transparent;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: var(--primary-text-color);
}

.MuiTooltip-tooltip {
  background-color: var(--dropdown-menu-background) !important;
  border-radius: 0.3rem;
  border: 2px solid var(--secondary-border-color);
  box-shadow: var(--flight-nav-bar-item-shadow);
  text-align: center;
  width: 15rem;
  scale: 2;
}  

.available-view::before{
  content: '';
  display: block;
  width: 2.7rem;
}

.MuiSelect-root .static-text{
  display: none;
}

.check-icon {
  position: absolute;
  left: -0.2rem;
}

.manage-views-container .MuiSelect-select:focus {
  background-color: transparent;
}

.view-layout-divider {
  max-width: 37.6rem;
  margin: 0.6rem 1rem;
  border-top: 1px solid var(--secondary-button-disabled-text);
}

.layout-option {
  display: flex;
  align-items: center;
}

.layout-option-icon {
  padding-right: 0.8rem;
}

.manage-views-dropdown .MuiSelect-root .layout-option .layout-option-icon{
  display: none;
}

.manage-views-dropdown .manage-views-dropdown .MuiSelect-root {
  color: transparent;
  padding: 0 !important;
}

.manage-views-dropdown .MuiInputBase-root {
  display: block;
}

.manage-views-dropdown .MuiSelect-root {
  height: 125%; 
}

.manage-views-container.disabled .single-select-dropdown-container .MuiSelect-icon {
  pointer-events: none;
  color: var(--gray);
}