.cancel-flight-form-sub-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Override Material-UI provided font family */
.cancel-flight-form-container .MuiFormLabel-root,
.cancel-flight-form-container .MuiInputBase-root,
.cancel-flight-form-container .MuiTypography-body1 {
  font-family: var(--defaultFontFamily);
}
