.summary-panel-mini .summary-panel-footer,
.summary-panel-expanded .summary-panel-footer {
  display: flex;
  justify-content: space-between;
  position: fixed;
  overflow: hidden;
  background: var(--flight-nav-bar-background);
  height: var(--constant-gantt-bottom-summary-mini-height);
  width: calc(100vw);
  bottom: 0;
  margin: 0;
  z-index: 1000;
}
.summary-panel-mini .summary-panel-footer .details,
.summary-panel-expanded .summary-panel-footer .details {
  flex: auto;
}
.summary-panel-mini .summary-panel-footer {
  height: var(--constant-gantt-bottom-summary-mini-height);
}
.swap-mode-active.summary-panel-mini .summary-panel-footer {
  height: var(--constant-gantt-bottom-summary-mini-height-swapmode);
}
.summary-panel-expanded .summary-panel-footer {
  height: var(--constant-gantt-bottom-summary-expanded-height);
  z-index: 12;
}
.swap-mode-active.summary-panel-expanded .summary-panel-footer {
  height: var(--constant-gantt-bottom-summary-expanded-height-swapmode);
}
.summary-panel-footer.maximized, .swap-mode-active.summary-panel-expanded .summary-panel-footer.maximized{
  height: calc(100% - 114px);
  z-index: 1011; /* to avoid overlapping with .timeline-timezone-label-container and .swap-mode-active.summary-panel-expanded .flights-gantt-footer */
}
.hide-header .summary-panel-footer.maximized, .hide-header .swap-mode-active.summary-panel-expanded .summary-panel-footer.maximized {
  height: calc(100% - 54px);
}
.summary-panel-expand,
.summary-panel-contract {
  float: right;
}
.summary-panel-footer .icon-bar {
  width: 40px;
  padding-top: 0.6rem;
  top: 0;
  padding-right: 0.2rem;
  right: 0;
  position: absolute;
}
.summary-panel-footer .icon-bar svg {
  cursor: pointer;
  height: 1.6rem;
  background-color: var(--flight-nav-bar-background);
  color: #81caff;
}

.summary-panel-footer .icon-bar.disabledExpandIcon svg {
  color: var(--gray);
}
.icon-bar.disabledExpandIcon {
  pointer-events: none;
}
.summary-panel-footer.maximized.multiple-view-enabled {
  position: absolute;
  top: 3rem;
  height: calc(100% - 3rem);
}

.operations-view.swap-mode-active.summary-panel-expanded .summary-panel-footer.maximized.multiple-view-enabled {
  height: 100%;
  top: 0;
}

/* for small screens */
@container (max-width: 992px) {
  .summary-panel-expanded .summary-panel-footer,
  .swap-mode-active.summary-panel-expanded .summary-panel-footer {
    height: calc(0.5rem + var(--constant-gantt-bottom-summary-expanded-height-portrait));
  }
  .summary-panel-footer.maximized, .swap-mode-active.summary-panel-expanded .summary-panel-footer.maximized{
    height: calc(100% - 114px);
  }

  .summary-panel-footer.maximized.multiple-view-enabled {
    height: calc(100% - 3rem);
  }
}
