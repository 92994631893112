.irrops-update-reason-modal .padding-gutters {
  padding-right: 6.4rem !important;
  height: 5.95rem;
}

.irrops-update-reason-modal .textareafield {
  padding-bottom: 0.3rem !important;
}

.databrick-stations-arrowright {
  color: rgba(0, 0, 0, 0.15);
  margin: 0rem 0.2rem 0.1rem 0.2rem;
  height: 1.3rem;
}
.irrops-update-reason-modal .textarea-comment {
  padding-bottom: 0.5rem !important;
}
.irrops-update-reason-modal .last-modified-date {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(166, 166, 166);
  text-align: right;
  float: right;
  margin-top: 0.3rem;
}
.irrops-update-reason-modal .databrick-stations-arrowright {
  margin: 0rem 0.2rem 0rem 0.2rem;
  height: 1.3rem;
}

@media (max-width: 62rem) {
  .irrops-update-reason-modal .padding-gutters {
    padding-right: 1.75rem !important;
  }
  .irrops-update-reason-modal .last-modified-date {
    margin-right: -1rem !important;
  }
}
