.swap-warning-badge-container {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.25rem;
    background-color: var(--warning-color, #DE750C);
    padding: 0.125rem;
    margin: 0.125rem;
    padding-right: 0.5rem;
}

.swap-warning-badge-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    margin-right: 0.2rem;
    font-size: 1.1rem;
}

.swap-warning-badge-message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25rem;
    font-size: 1.1rem;
}