.crosshair-count-select {
  height: 100%;
}

.crosshair-count-select .crosshair-count-select {
  width: 26px;
  height: 100%;
  border: none;
  background-color: var(--flight-nav-bar-icon-background);
  border-radius: 0 0.6rem 0.6rem 0;
  margin-left: -0.15rem;
}

.crosshair-count-select .crosshair-count-select .MuiSelect-root {
  padding: 0 !important;
  color: transparent;
}

.crosshair-count-select .crosshair-count-select svg {
  width: 10px;
  height: 10px;
  top: calc(50% - 10px);
  margin: 0.5rem 0.75rem;
}

.crosshair-count-select .MuiInputBase-root {
  display: block;
}

.crosshair-count-select .MuiSelect-root {
  height: 100%;
}
