.flight-swap.flight-nav-bar-item {
  border-radius: 0.6rem;
  padding-top: 0.4rem;
  width: 3.4rem;
  height: 3rem;
  margin-left: 0.6rem;
  text-align: center;
  box-shadow: var(--flight-nav-bar-item-shadow);
  cursor: pointer;
  background-color: var(--flight-nav-bar-icon-background);
}

.flight-swap.swap-inactive-mode .flight-swap-icon {
  color: var(--flight-nav-bar-icon-fill);
}

.flight-swap.swap-active-mode .flight-swap-icon {
  color: var(--flight-nav-bar-icon-background);
}

.flight-swap.flight-nav-bar-item.swap-active-mode {
  box-shadow: var(--flight-nav-bar-item-shadow);
  background-color: var(--gantt-flight-toggle-selected-background);
}
