.add-flight-icon {
  color: var(--flight-nav-bar-icon-fill);
  background-color: var(--flight-nav-bar-icon-background);
}

.add-button-container {
  border-radius: 0.6rem;
  cursor: pointer;
  width: 3.4rem;
  height: 3rem;
  box-shadow: var(--flight-nav-bar-item-shadow);
  display: flex;
  align-items: center;
  margin-left: 0.6rem;
  justify-content: center;
  background-color: var(--flight-nav-bar-icon-background);
}

.add-button-container.disabled .add-flight-icon {
  color: var(--gray);
}

.add-button-container.disabled {
  pointer-events: none;
}
