.ground-puck {
  height: var(--puck-height);
  line-height: var(--puck-line-height);
  font-size: var(--ground-station-font-size);
  font-weight: 700;
  text-align: center;
}

.ground-puck.ots-ground-puck {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: var(--puck-border-radius);
  box-shadow: var(--puck-box-shadow);
}

/* OTS Ground Puck */
.ground-puck.ots-classic.ots-puck-adv,
.ground-puck.ots-high-contrast.ots-puck-adv,
.ground-puck.ots-modern-dark,
.ground-puck.ots-modern-light {
  background-color: var(--ground-puck-out-of-service-background);
  color: var(--ground-puck-out-of-service-text);
}

/* Block Ground Puck */
.ground-puck.block-ground-puck {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: var(--puck-border-radius);
  box-shadow: var(--puck-box-shadow);
}

.ground-puck.block-classic,
.ground-puck.block-high-contrast,
.ground-puck.block-modern-dark,
.ground-puck.block-modern-light {
  background-color: var(--ground-puck-block-background);
  color: var(--ground-puck-block-text);
}

.ground-puck.ots-classic.ots-puck-etr,
.ground-puck.ots-high-contrast.ots-puck-etr,
.ground-puck.ots-high-contrast.ots-puck-comp,
.ground-puck.ots-classic.ots-puck-comp {
  background-color: #dbdbdb;
  color: black;
}

.ground-puck.ots-high-contrast.ots-puck-comp-etr ,
.ground-puck.ots-classic.ots-puck-comp-etr {
  background-color: #dbdbdb;
  color: black;
}

.ground-puck > .flight-action-menu {
  position: absolute;
  cursor: pointer;
  top: var(--flight-action-menu-top);
  font-size: var(--flight-action-menu-icon-size);
  z-index: 99;
  height: var(--flight-action-menu-height);
  width: var(--flight-action-menu-width);
}

.ground-puck > .flight-action-menu.short-puck-flag {
  border-radius: 0.2rem;
  justify-content: center;
}

.ground-puck .flight-action-menu .action-menu {
  position: relative;
  display: flex;
  align-items: center;
  visibility: hidden;
  bottom: 1.4rem;
  padding-left: 0.6rem;
  padding-right: var(--flight-action-menu-padding);
}

.ground-puck:hover .flight-action-menu .action-menu {
  visibility: visible;
}

.ground-puck.selectable:hover {
  cursor: pointer;
}

.ground-puck.ots-classic.ots-puck-etr,
.ground-puck.ots-high-contrast.ots-puck-etr,
.ground-puck.ots-high-contrast.ots-puck-comp .selectable:hover,
.ground-puck.ots-classic.ots-puck-comp .selectable:hover {
  cursor: pointer;
}

.flight-puck:hover .flight-action-menu .action-menu {
  visibility: visible;
}

.flight-puck.selectable:hover {
  cursor: pointer;
}

.group-puck-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-puck-container > .flight-action-menu {
  position: absolute;
  cursor: pointer;
  right: 0;
  font-size: var(--flight-action-menu-icon-size);
  z-index: 99;
  height: var(--flight-action-menu-height);
  width: var(--flight-action-menu-width);
}

.ground-puck .menu-action-ellipsis {
  color: black;
}

.ground-puck.block-ground-puck .menu-action-ellipsis {
  color: var(--ground-puck-block-text);
}

.ground-puck-access-menu {
  display: flex;
  justify-content: end;
}

.ground-puck {
  height: var(--puck-height);
  line-height: var(--puck-line-height);
  font-size: var(--ground-station-font-size);
  font-weight: 700;
  text-align: center;
}

.ground-puck.ots-ground-puck {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: var(--puck-border-radius);
  box-shadow: var(--puck-box-shadow);
}

/* OTS Ground Puck */
.ground-puck.ots-classic.ots-puck-adv,
.ground-puck.ots-high-contrast.ots-puck-adv,
.ground-puck.ots-modern-dark,
.ground-puck.ots-modern-light {
  background-color: var(--ground-puck-out-of-service-background);
  color: var(--ground-puck-out-of-service-text);
}

.ground-puck.ots-high-contrast.ots-puck-etr,
.ground-puck.ots-high-contrast.ots-puck-comp,
.ground-puck.ots-classic.ots-puck-comp {
  background-color: #dbdbdb;
  color: black;
}

.ground-puck > .flight-action-menu {
  position: absolute;
  cursor: pointer;
  top: var(--flight-action-menu-top);
  font-size: var(--flight-action-menu-icon-size);
  z-index: 99;
  height: var(--puck-height);
  width: var(--flight-action-menu-width);
}

.ground-puck > .flight-action-menu.short-puck-flag {
  border-radius: 0.2rem;
  justify-content: center;
}

.ground-puck .flight-action-menu .action-menu {
  position: relative;
  display: flex;
  align-items: center;
  visibility: hidden;
  top: 0;
  height: 100%;
  padding-right: 0.6rem;
}
.ground-puck .flight-action-menu .app-insights-tracking-container {
  height: 100%;
}

.ground-puck .flight-action-menu .menu-action-ellipsis {
  height: var(--flight-action-menu-icon-size);
}

.group-puck-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-puck-container > .flight-action-menu {
  position: absolute;
  cursor: pointer;
  right: 0;
  font-size: var(--flight-action-menu-icon-size);
  z-index: 99;
  height: 100%;
  width: var(--flight-action-menu-width);
}

.ground-puck .menu-action-ellipsis {
  color: black;
}

.ground-puck.ots-ground-puck.ots-classic.ots-puck-etr,
.ground-puck.ots-ground-puck.ots-high-contrast.ots-puck-etr,
.ground-puck.ots-ground-puck.ots-high-contrast.ots-puck-comp .menu-action-ellipsis,
.ground-puck.ots-ground-puck.ots-classic.ots-puck-comp .menu-action-ellipsis {
  color: black;
}

.ground-puck-access-menu {
  display: flex;
  justify-content: end;
  top: 0;
  z-index: 99;
  position: absolute;
  width: inherit;
  height: 100%;
}

.ground-puck-access-menu-no-label {
  display: flex;
  justify-content: end;
  position: relative;
  bottom: -1.5rem;
  left: 0.4rem;
}

.ground-puck.ots-ground-puck.ots-classic.ots-puck-etr,
.ground-puck.ots-ground-puck.ots-high-contrast.ots-puck-etr,
.ground-puck.ots-ground-puck.ots-high-contrast.ots-puck-comp:hover,
.ground-puck.ots-ground-puck.ots-classic.ots-puck-comp:hover {
  visibility: visible;
}

.ground-puck.ots-ground-puck.ots-classic.ots-puck-etr,
.ground-puck.ots-ground-puck.ots-high-contrast.ots-puck-etr,
.ground-puck.ots-ground-puck.ots-high-contrast.ots-puck-comp:hover,
.ground-puck.ots-ground-puck.ots-classic.ots-puck-comp:hover {
  cursor: pointer;
}

.ground-puck:hover .flight-action-menu .action-menu {
  visibility: visible;
}
.taxi-block.phase-in > .ground-puck-access-menu {
  right: 2;
}

.flightpuck-container.selected-puck:hover {
  cursor: pointer;
}
