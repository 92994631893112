.watch-flight-modal .padding-gutters {
  padding-right: 6.4rem !important;
  height: 5.95rem;
}

.watch-flight-modal .textareafield {
  padding-bottom: 0.3rem !important;
}

.databrick-stations-arrowright {
  color: rgba(0, 0, 0, 0.15);
  margin: 0rem 0.2rem 0.1rem 0.2rem;
  height: 1.3rem;
}
.watch-flight-modal .databrick-stations-arrowright {
  margin: 0rem 0.2rem 0rem 0.2rem;
  height: 1.3rem;
}
.watch-flight-modal-form .dropdownsearchable-container,
.watch-flight-modal-form .textinput,
.watch-flight-modal-form .dropdownsearchable-paper .menulist {
  width: 39.2rem !important;
}

.watch-flight-modal.dialog .MuiDialog-paperWidthSm {
  max-width: 58rem;
}
.watch-flight-modal .dialog.thin .MuiDialog-paperWidthSm {
  min-height: 29rem;
}

@media (max-width: 62rem) {
  .watch-flight-modal .padding-gutters {
    padding-right: 1.75rem !important;
  }
}

@media (max-width: 38rem) {
  .watch-flight-modal-form .dropdownsearchable-container,
  .watch-flight-modal-form .textinput,
  .watch-flight-modal-form .dropdownsearchable-paper .menulist {
    width: 25.2rem !important;
  }
}
