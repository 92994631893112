.admin-page {
  height: calc(100vh - 6rem);
}

.admin-page {
  padding-top: 1.6rem;
}

.admin-page .button-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.6rem;
  padding-bottom: 1.6rem;
}

.admin-table-container {
  border: 2px solid #e2e2e2;
  overflow: auto;
  max-height: calc(100vh - 13.6rem);
}

.admin-table-container tr {
  border: 0;
  height: 4.4rem;
}

.admin-table-container th {
  border-bottom: 1px solid #f0f0f0;
  font-weight: bold;
  padding-top: 0;
  padding-bottom: 0;
}

.admin-table-container th svg {
  fill: #a6a6a6;
}

.admin-table-container td {
  padding-top: 0;
  padding-bottom: 0;
}

.admin-table-container tr:not(:last-child) td {
  border-bottom: 0;
}

.admin-table-container tr:last-child td {
  border-bottom: 0;
}

.admin-list-warning-message {
  background-color: #ffffff;
  border-color: #e2e2e2;
  border-style: solid;
  border-width: 2px 2px 0px 2px;
  color: #681515;
  padding: 0.5rem 1rem;
  width: 100%;
}

.admin-list-table-default-value {
  color: #353535;
}

.admin-table-container .secondary-label {
  color: #a6a6a6;
  font: bold 0.6875rem var(--defaultFontFamily);
}

.admin-table-container .secondary-label.zulu-label {
  text-transform: capitalize;
}

/* edit icon color */
.admin-table-container .MuiSvgIcon-colorPrimary {
  color: var(--primary-icon-color);
  font-size: 2.4rem;
}

.admin-table-container .MuiSvgIcon-colorPrimary:hover {
  cursor: pointer;
}
