/* ****** Delete after removing multiple views feature flag ****** */
.main-app-container-legacy {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main-app-container-legacy .page-content {
  background-color: var(--primary-container-background);
}
/* ************************************************************** */

.main-app-container {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh);
}

.main-app-container .page-content {
  background-color: var(--primary-container-background);
  flex: 1;
  overflow: hidden;
}