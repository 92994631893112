.MuiSkeleton-text.flight-tab-skeleton-text-1 {
  height: 2.67rem;
  width: 22.6rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.MuiSkeleton-text.flight-tab-skeleton-text-2 {
  height: 2.67rem;
  width: 16.67rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.MuiSkeleton-text.flight-tab-skeleton-text-3 {
  height: 2.67rem;
  width: 11.3rem;
  margin-bottom: 2.1rem;
  border-radius: 1rem;
}
.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-pulse {
  height: 2.67rem;
  margin-bottom: 2.1rem;
  border-radius: 1rem;
}

.flight-tab-skeleton-separator {
  margin-top: 1.5rem;
  height: 0.1rem;
  border-bottom: 1.5px solid var(--primary-border-color);
  margin-bottom: 1.5rem;
}
