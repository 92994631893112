/* material ui overrides */
.reinstate-flight-form-container
  .MuiInputBase-root.MuiInput-root
  .MuiInput-underline
  .MuiInputBase-formControl
  .MuiInput-formControl {
  width: 100%;
  margin-bottom: 1rem;
}
/* Override Material-UI provided font family */
.reinstate-flight-form-container .MuiFormLabel-root,
.reinstate-flight-form-container .MuiInputBase-root,
.reinstate-flight-form-container .MuiTypography-body1 {
  font-family: var(--defaultFontFamily);
}
