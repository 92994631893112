.single-select-dropdown-container label.MuiInputLabel-formControl {
  transform: translate(0, 1.2rem) scale(1);
}
/* dropdown arrow styling */
.single-select-dropdown-container .MuiSelect-icon {
  color: var(--primary-icon-color);
  margin: 0.5rem 1.159rem;
}

.single-select-dropdown-container .custom-icon {
  margin: 0.25rem 3.2rem;
}

.single-select-dropdown-container .MuiSelect-select.MuiSelect-select {
  padding: 1.5rem 2.5rem 1.4rem 1rem !important;
}

.MuiPopover-paper::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background: #f1f1f1;
}
.MuiPopover-paper::-webkit-scrollbar {
  width: 0.5rem;
  background: #f1f1f1;
}
.MuiPopover-paper::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #a6a6a6;
}
.single-select-dropdown-container .Mui-focused {
  border: 2px solid var(--primary-icon-color);
  height: 4.4rem;
}
.MuiPopover-root .MuiMenu-paper .MuiMenu-list .MuiListItem-gutters {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
