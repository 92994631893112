.new-filter-modal .modal-v2-footer-container.multi-buttons .modal-v2-footer-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.new-filter-modal .modal-v2-footer-container.multi-buttons .filter-modal-footer-button.clear .button {
  border: none;
  padding-top: 1.2rem;
}

.new-filter-modal .modal-v2-footer-container.multi-buttons.modal-footer {
  padding-bottom: 1.6rem;
}

.dialog-actions-buttons .filter-modal-footer-button.clear .button {
  border: none;
  background-color: transparent;
  color: var(--interaction-link-color);
  padding-top: 1.2rem;
}

.filter-modal-footer-button.clear.button:disabled {
  color: var(--gray)
}