.timeline-hours-container .timeline-hours-filter {
  max-height: 3rem;
  max-width: 9.5rem;
  font-weight: 700 !important;
  box-shadow: var(--flight-nav-bar-item-shadow);
  border-radius: 0.6rem;
  border: none;
  background: var(--flight-nav-bar-icon-background);
}
.timeline-hours-container .MuiSelect-select:focus {
  background-color: transparent;
}
.timeline-hours-filter-popover {
  margin-top: -0.6rem;
}
.timeline-hours-filter-MuiMenu-list {
  font-weight: 600 !important;
}


.timeline-hours-filter > .MuiInput-underline:after {
  border-bottom: none !important;
}
.timeline-hours-filter > .MuiInput-underline:before {
  border-bottom: none !important;
}
