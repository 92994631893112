.zoom-container {
  display: flex;
  padding-right: 0.6rem;
  padding-left: 0.6rem ;
}
.zoom-container .button {
  border: none;
  background-color: var(--flight-nav-bar-icon-background);
  min-width: 3.6rem;
  height: 3rem;
  box-shadow: var(--flight-nav-bar-item-shadow);
}

.zoom-container .zoom-in .button {
  border-radius: 0.6rem 0 0 0.6rem;
  margin-right: -0.28rem;
}

.zoom-container .zoom-out .button {
  border-radius: 0 0.6rem 0.6rem 0;
  box-shadow: var(--flight-nav-bar-item-shadow-2);
  margin-left: -0.28rem;
}

.zoom-container .button:disabled .fa-magnifying-glass-minus {
  color: var(--secondary-button-disabled-text);
}

.zoom-container .button:disabled .fa-magnifying-glass-plus {
  color: var(--secondary-button-disabled-text);
}
.zoom-container .vertical-line {
  border-left: 4px solid var(--secondary-button-disabled-text);
  height: 1.6rem;
  align-self: center;
}
