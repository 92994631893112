.button-group {
  display: flex;
  height: 3rem;
}

.button-group .button {
  border: none;
  background-color: var(--flight-nav-bar-icon-background);
  margin-left: 0.6rem;
  min-width: 3.6rem;
  box-shadow: var(--flight-nav-bar-item-shadow);
}

.button-group .button:first-child {
  border-radius: 0.6rem 0 0 0.6rem;
}

.button-group .button:last-child {
  border-radius: 0 0.6rem 0.6rem 0;
  box-shadow: var(--flight-nav-bar-item-shadow-2);
}
