.flights-container {
  background-color: var(--page-container-background);
  color: var(--page-container-text);
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.flight-list-view-container {
  display: flex;
  flex-direction: column;
}

.add-flight-div {
  margin-left: auto;
  right: 0px;
}

.flight-list-view-table-section {
  position: relative;
  overflow: hidden;
}

.new-modal-filters .flight-view-content {
  height: calc(100vh - 12rem);
}
/* adjust height of the gantt when header is hidden */
.page-content.hide-header .new-modal-filters .flight-view-content {
  height: calc(100vh - 6rem);
}

.legacy-filters .flight-view-content {
  height: calc(100vh - 11.2rem);
}

.MuiPopover-root {
  top: 10rem;
}

.flight-list-view-container .flight-list-view-grid {
  display: flex;
  height: inherit;
}

.flight-list-view-container .flight-list-view-grid .grid-col-flight-table {
  flex-grow: 0;
  width: calc(100vw - 50rem);
}

.flight-list-view-container .flight-list-view-grid .grid-col-flight-pane {
  flex-grow: 1;
  position: fixed;
  width: 50rem;
  box-sizing: border-box;
  right: 0;
  height: 100%;
  z-index: 1000;
}

/* table or gantt view content when detail pane is closed */
.flight-list-view-container .flight-list-view-grid .grid-col-flight-table.full-screen {
  flex: auto;
}

div.grid-col-flight-pane > div:first-child {
  height: 100%;
}

.flights-container.swap-mode-active .flights-sub-contianer {
  margin: -0.65rem;
}

.flights-container.summary-panel-mini .flights-sub-contianer,
.flights-container.summary-panel-expanded .flights-sub-contianer {
  margin-bottom: -0.65rem;
  margin-top: -0.65rem;
}

.swap-mode-active .flight-list-view-grid .grid-col-flight-pane {
  position: inherit;
}

.flights-container.swap-mode-active {
  display: grid;
  border: 0.6rem solid var(--gantt-flight-toggle-selected-background);
  padding: 0.05rem;
  overflow: hidden;
}

.flights-container.summary-panel-mini,
.flights-container.summary-panel-expanded {
  display: grid;
  overflow: hidden;
}

.swap-mode-active .flight-list-view-container.new-modal-filters {
  position: relative;
  width: calc(100vw - 0.6rem);
}
