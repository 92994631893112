.classic-flight-puck {
  background: #b3b3b3;
  border-radius: var(--puck-border-radius) !important;
  color: var(--puck-label-color);
  display: inline-flex;
  font-size: var(--flight-number-font-size);
  font-weight: 800;
  height: var(--puck-height);
  line-height: var(--puck-line-height);
  text-align: center;
  align-content: center;
  position: relative;
}
.classic-flight-puck.taxi-block.phase-pre-flight.equipmentchange-flight,
.classic-flight-puck.taxi-block.phase-pre-flight.equipmentchange-flight:hover .short-puck-flag {
  background: var(--puck-phase-eqip);
}

.classic-flight-puck.taxi-block.phase-pre-flight.tailbreak-flight,
.classic-flight-puck.taxi-block.phase-return-to-gate.tailbreak-flight,
.classic-flight-puck.taxi-block.phase-on-in-only.tailbreak-fligh,
.classic-flight-puck.taxi-block.phase-pre-flight.tailbreak-fligh:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-return-to-gate.tailbreak-fligh:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-on-in-only.tailbreak-fligh:hover .short-puck-flag {
  background: var(--puck-phase-tailbreak);
  color: var(--flight-number-color-tailbreak);
}

.classic-flight-puck.taxi-block.phase-pre-flight.watch-flight,
.classic-flight-puck.taxi-block.phase-return-to-gate.watch-flight,
.classic-flight-puck.taxi-block.phase-on-in-only.watch-flight,
.classic-flight-puck.taxi-block.phase-pre-flight.watch-flight:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-return-to-gate.watch-flight:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-on-in-only.watch-flight:hover .short-puck-flag {
  background: var(--puck-phase-watch) !important;
  color: var(--puck-label-color);
}
.classic-flight-puck.taxi-block.phase-pre-flight.shortturn-flight,
.classic-flight-puck.taxi-block.phase-return-to-gate.shortturn-flight,
.classic-flight-puck.taxi-block.phase-on-in-only.shortturn-flight,
.classic-flight-puck.taxi-block.phase-pre-flight.shortturn-flight:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-return-to-gate.shortturn-flight:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-on-in-only.shortturn-flight:hover .short-puck-flag {
  background: var(--puck-phase-shortturn) !important;
  color: var(--puck-label-color);
}
.classic-flight-puck.taxi-block.phase-pre-flight.infoby-flight,
.classic-flight-puck.taxi-block.phase-pre-flight.infoby-flight:hover .short-puck-flag {
  background: var(--puck-phase-infoby) !important;
  color: var(--puck-label-color);
}

.classic-flight-puck.taxi-block.phase-off,
.classic-flight-puck.taxi-block.phase-off-only,
.classic-flight-puck.taxi-block.phase-off-on-only,
.classic-flight-puck.taxi-block.phase-off:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-off-only:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-off-on-only:hover .short-puck-flag {
  background-color: var(--puck-phase-off);
  color: var(--puck-label-color);
}
.classic-flight-puck.taxi-block.phase-in,
.classic-flight-puck.taxi-block.phase-in-only,
.classic-flight-puck.taxi-block.phase-on-in-only,
.classic-flight-puck.taxi-block.phase-return-to-gate,
.classic-flight-puck.taxi-block.phase-out-in-only,
.classic-flight-puck.taxi-block.phase-in:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-in-only:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-on-in-only:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-return-to-gate:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-out-in-only:hover .short-puck-flag {
  background-color: var(--puck-phase-in) !important;
  color: var(--puck-label-color);
}
.classic-flight-puck.taxi-block.phase-on,
.classic-flight-puck.taxi-block.phase-on-only,
.classic-flight-puck.taxi-block.phase-on:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-on-only:hover .short-puck-flag {
  background: var(--puck-phase-on);
  color: var(--flight-number-color-out-on);
}
.classic-flight-puck.taxi-block.phase-out,
.classic-flight-puck.taxi-block.phase-out-on-only,
.classic-flight-puck.taxi-block.phase-out:hover .short-puck-flag,
.classic-flight-puck.taxi-block.phase-out-on-only:hover .short-puck-flag {
  background: var(--puck-phase-out);
  color: var(--flight-number-color-out-on);
}

.classic-flight-puck.taxi-block.phase-pre-flight,
.classic-flight-puck.taxi-block.phase-pre-flight:hover .short-puck-flag {
  background: var(--puck-phase-planned);
  color: var(--puck-label-color);
}

.flightpuck-container
  .classic-flight-puck.taxi-block.phase-pre-flight.equipmentchange-flight:hover
  .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-eqip-transparent);
}

.flightpuck-container .classic-flight-puck.taxi-block.phase-in.watch-flight:hover .flight-action-menu.short-puck-flag,
.flightpuck-container
  .classic-flight-puck.taxi-block.phase-in-only.watch-flight:hover
  .flight-action-menu.short-puck-flag,
.flightpuck-container
  .classic-flight-puck.taxi-block.phase-pre-flight.watch-flight:hover
  .flight-action-menu.short-puck-flag,
.flightpuck-container
  .classic-flight-puck.taxi-block.phase-return-to-gate.watch-flight:hover
  .flight-action-menu.short-puck-flag,
.flightpuck-container
  .classic-flight-puck.taxi-block.phase-on-in-only.watch-flight:hover
  .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-watch-transparent);
}

.flightpuck-container .classic-flight-puck.taxi-block.phase-in:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .classic-flight-puck.taxi-block.phase-in-only:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .classic-flight-puck.taxi-block.phase-on-in-only:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .classic-flight-puck.taxi-block.phase-return-to-gate:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .classic-flight-puck.taxi-block.phase-out-in-only:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-in-transparent);
}

.flightpuck-container .classic-flight-puck.taxi-block.phase-pre-flight:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-planned-transparent);
}
.flightpuck-container .classic-flight-puck.taxi-block.phase-out:hover .flight-action-menu.short-puck-flag,
.classic-flight-puck.taxi-block.phase-out-on-only:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-out-transparent);
}

.flightpuck-container .classic-flight-puck.taxi-block.phase-off:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .classic-flight-puck.taxi-block.phase-off-only:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .classic-flight-puck.taxi-block.phase-off-on-only:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-off-transparent);
}

.flightpuck-container .classic-flight-puck.taxi-block.phase-on:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .classic-flight-puck.taxi-block.phase-on-only:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-on-transparent);
}

.classic-flight-puck > .flight-number {
  height: inherit;
  vertical-align: top;
  width: inherit;
  position: absolute;
}

.swap-flight-puck {
  background: var(--puck-swap-flight-background);
}

.group-puck-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-puck-container > .flight-action-menu {
  position: absolute;
  cursor: pointer;
  right: 0;
  font-size: var(--flight-action-menu-icon-size);
  z-index: 99;
  height: var(--flight-action-menu-height);
  width: var(--flight-action-menu-width);
}

.selected-group-puck {
  background: var(--puck-selected-group-background-overlay);
  position: relative;
  align-items: center;
  border: 2px solid var(--puck-selected-group-border-color);
  border-radius: var(--puck-border-radius);
}

.classic-flight-puck {
  display: flex;
  justify-content: end;
}

.flightpuck-container.short-puck-flag .classic-flight-puck {
  justify-content: center;
}

.classic-flight-puck > .flight-action-menu {
  position: absolute;
  cursor: pointer;
  top: var(--flight-action-menu-top);
  font-size: var(--flight-action-menu-icon-size);
  z-index: 99;
  height: var(--flight-action-menu-height);
  width: var(--flight-action-menu-width);
}

.classic-flight-puck > .flight-action-menu.short-puck-flag {
  border-radius: 0.2rem;
  justify-content: center;
}

.classic-flight-puck .flight-action-menu .action-menu {
  position: relative;
  display: flex;
  align-items: center;
  visibility: hidden;
  height: var(--puck-height);
  bottom: var(--flight-action-menu-bottom);
  padding-left: var(--flight-action-menu-padding);
  padding-right: var(--flight-action-menu-padding);
}

.classic-flight-puck:hover .flight-action-menu .action-menu {
  visibility: visible;
}

.classic-flight-puck.selectable:hover {
  cursor: pointer;
}
.classic-flight-puck.taxi-block.phase-pre-flight.departure-delay {
  border-color: var(--puck-phase-delayed);
  background-color: var(--puck-phase-delayed)  !important;
  color: var(--puck-label-color);
}

/*classic them flight puck background color apply flight in phase in */
.flight-puck-container > .darkline-early {
  border-color: var(--puck-phase-early-dark) !important;
  background-color: var(--puck-phase-early-dark) !important;
}
.flight-puck-container > .darkline-delay {
  border-color: var(--puck-phase-late-dark) !important;
  background-color: var(--puck-phase-late-dark) !important;
  transform: rotate(180deg) !important;
  transform-origin: left center !important;
}

.classic-flight-puck.cancelled {
  z-index: 100;
}

.flight-puck .flight-action-menu .action-menu {
  display: flex;
  align-items: center;
  visibility: hidden;
  height: var(--puck-height);
}

.flight-puck:hover .flight-action-menu .action-menu {
  visibility: visible;
}

.flight-puck.selectable:hover {
  cursor: pointer;
}

.flight-puck-container > .alert-puck-item > .MuiBadge-root {
  vertical-align: sub;
}

/* select canceled flight puck style */
.classic-flight-puck.selected.cancelled,
.classic-flight-puck.selected.taxi-block.cancelled {
  /* repeating 1px lines at 45 degrees */
  background: repeating-linear-gradient(
    -45deg,
    var(--selected-flight-puck-background),
    var(--selected-flight-puck-background) 9px,
    #b3b3b3 10px,
    #b3b3b3 11px
  );
  border: 1px solid var(--selected-flight-border);
  color: black;
}
.classic-flight-puck.selected.cancelled .flightnumber-text,
.classic-flight-puck.selected.cancelled:hover .flightnumber-text,
.classic-flight-puck.selected.taxi-block.cancelled .flightnumber-text,
.classic-flight-puck.selected.taxi-block.cancelled:hover .flightnumber-text {
  background-color: #d9d9d9 !important;
  border-radius: 1px;
  margin-top: 2px;
  padding: 0 2px 0 2px;
  opacity: 1 !important;
}
.flight-number,
.flight-number:hover {
  opacity: 1 !important;
}
/* canceled flight puck style */
.classic-flight-puck.cancelled,
.classic-flight-puck.taxi-block.cancelled,
.classic-flight-puck.cancelled.taxi-block.infoby-flight,
.classic-flight-puck.cancelled.infoby-flight,
.classic-flight-puck.cancelled.taxi-block.phase-pre-flight.shortturn-flight {
  /* repeating 1px lines at 45 degrees */
  background: repeating-linear-gradient(
    -45deg,
    #d9d9d9,
    #d9d9d9 9px,
    var(--dark-primary) 10px,
    var(--dark-primary) 11px
  ) !important;
}
.flightnumber-text,
.flightnumber-text:hover {
  opacity: 1 !important;
}
.classic-flight-puck.cancelled .flightnumber-text,
.classic-flight-puck.taxi-block.cancelled .flightnumber-text,
.classic-flight-puck.cancelled.taxi-block.infoby-flight .flightnumber-text,
.classic-flight-puck.cancelled.infoby-flight .flightnumber-text {
  position: relative;
  background-color: #d9d9d9;
  border-radius: 1px;
  padding: 0 2px 0 2px;
  height: 100%;
  display: inline-block;
  color: var(--dark-primary);
}

.swap-mode-active .flightnumber-text,
.swap-mode-active .flight-number,
.swap-mode-active .classic-flight-puck,
.swap-mode-active .flightpuck-container,
.swap-mode-active .puck-container {
  user-select: none;
}
