.flifo-modal .dialog-actions-buttons {
    width: 30%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.align-horizontal{
    display: flex;
}

.vertical-line-grid {
    display: flex;
    justify-content: center;
    max-width: fit-content;
}

.search-icon.disable {
    color: var(--darkerGray);
}

.search-icon.enable {
    cursor: pointer;
}

.flifo-flight-container.outlined .MuiOutlinedInput-root,
.flifo.origin-input.outlined,
.flifo.dest-input.outlined {
    border-color: var(--secondary-hover-color);
}

.MuiFormControl-root.as-date-picker.flifo-date-picker.outlined .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--secondary-hover-color);
}

.notification-toast.flifo-toast {
    z-index: 1301;
}

.out-time-input .MuiOutlinedInput-input::placeholder,
.off-time-input .MuiOutlinedInput-input::placeholder,
.flifo-times-on .MuiOutlinedInput-input::placeholder,
.flifo-times-in .MuiOutlinedInput-input::placeholder {
    color: #ffffff;
    opacity: 1;
    font-style: normal;
}

.flifo-etd-container .MuiInputAdornment-positionEnd,
.flifo-sched-eta .MuiInputAdornment-positionEnd,
.flifo-eta-container .MuiInputAdornment-positionEnd {
    padding-right: 1.6rem;
}

.flifo-etd-container .MuiOutlinedInput-input::placeholder,
.flifo-sched-eta .MuiOutlinedInput-input::placeholder,
.flifo-eta-container .MuiOutlinedInput-input::placeholder {
    color: #989898;
    opacity: 1;
    font-style: normal;
}

.schedule-departure-input-container .MuiOutlinedInput-input::placeholder {
    font-style: normal;
}

.schedule-departure-time-input .MuiInputAdornment-root.MuiInputAdornment-positionEnd{
    display: none;
}

.flifo-etd-container, .flifo-eta-container{
    position: relative;
    padding-bottom: 25px;
}

.copy-icon{
    position: absolute;
    left: 85%;
    top: 25%;
    scale: 1.3;
}

.copy-tooltip{
    position: absolute !important;
    left: 78%;
    top: 20%;
    scale: 1.0;
}

.text-grid {
    align-items: center;
    display: flex;
}

.input-container {
    align-content: center;
}

.grid-item-container{
    position: relative;
    padding-bottom: 10px;
}

.position-relative{
    position: relative;
}

.schedule-departure-input-container {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 5px;
    justify-content: flex-start;
}

.column-alignment {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    max-width: 90%;
}

.search-icon-rectangle{
    width: 28px;
    height: 26px;
    border-radius: 6px;
    background: var(--flifo-search-icon-background);
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-icon{
    color: var(--lightBlue);
}

.search-icon-container{
    align-self: center;
    display: flex;
    justify-content: flex-end;
}

.heading-row{
    height: 46px;
}

.vertical-line{
    font-weight: bold;
    height: 96%;
    width: 1px;
    background-color: white;
    opacity: 50%;
    margin-top: 8px;
}

.schedule-departure-time-input{
    width: 7.5rem
}

.container-grid{
    display: flex;
    justify-content: center;
    gap: 19px
}

.flifo-modal .MuiDialogActions-root.dialog-actions-container.thin.multi-buttons,
.flifo-modal .MuiDialogActions-root.dialog-actions-container.wide.multi-buttons {
    padding-bottom: 3.6rem !important;
}

.input-container.flight-number {
    padding-left: 0.8rem;
}

 .input-container.flight-number .text-grid { 
    padding-left: 0.8rem;
    margin-right: 0.8rem;
}

@media (max-width: 62rem) {
    .search-icon-container {
        align-self: flex-start;
        padding-top: 1rem;
    }

    .flifo-modal .origin .airport-code-input .airport-code-input-dropdown.show{
        right: auto;
        left: 0;
      }

    .flifo-modal .MuiDialog-paper{
        max-width : 48rem !important;
    }

    .flifo-modal .dialog-actions-buttons {
        justify-content: center;
    }

    .button-container.flifo-modal-cancel-button {
        padding-right: 0.4rem
    }

    .button-container.flifo-modal-update-button {
        padding-left: 0.4rem
    }

    .flifo-modal .align-horizontal.origin,
    .flifo-modal .align-horizontal.dest {
        padding-top: 2rem;
    }

    .lifo-flight-container .MuiOutlinedInput-adornedEnd {
        padding-right: 1rem;
    }

    .input-container.flight-number {
        padding-left: 0;
    }
}