.chipinput .MuiChip-root {
  border-radius: 0.32rem;
  background-color: var(--chip-input-background);
  margin-right: 0.64rem;
  height: 2.5rem;
  margin-top: 0.64rem;
}
.chipinput .MuiChip-label {
  font-size: 1.4rem;
  padding-right: 0.928rem;
  padding-left: 0.768rem;
  padding-top: 0.08rem;
  font-weight: 800;
}

.chipinput .MuiChip-deleteIcon {
  width: 1rem;
  height: 1rem;
  color: #a8a8a8;
  margin-bottom: -0.0592rem;
  margin-right: 0.752rem;
}

/* TODO */
.chipinput input.MuiInputBase-input {
  padding: 0;
  height: 2.5rem;
  margin-top: 0.64rem;
  font-family: var(--defaultFontFamily);
  font-weight: bold;
}

.chipinput div.MuiOutlinedInput-adornedStart {
  padding-left: 0.96rem;
}

.chipinput .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none;
}

.chipinput .MuiOutlinedInput-notchedOutline {
  border: none;
}
