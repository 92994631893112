.flight-info-tabs-container{
    top: -45px;
    position: relative;
}
.flight-info-tabs-container.deadheads-expanded{
    top: 8px
}
.flightinfo-tabs .MuiTabs-indicator{
    background-color: var(--primary-button-background)!important;
    top: 0;
    height: 4px;
}

.flightinfo-tabs .MuiTabs-flexContainer{
    border-bottom: 1px solid var(--primary-border-color);
}
.flightinfo-tabs button.flightinfo-tab{
    background-color: var(--dark-primary);
    font-size: 14px;
    font-weight: 700;
    text-transform: none;
}
.flightinfo-tabs button.flightinfo-tab.Mui-selected{
    border-right: 1px solid var(--primary-border-color);
    border-left: 1px solid var(--primary-border-color);
    top: 1px;   
}
.flightinfo-tabs button.flightinfo-tab.Mui-selected .MuiTab-wrapper{
    color: var(--primary-button-background);
}
    
.flight-info-tables .collapse-region{
    width: 100%;
}

.flight-info-tables .collapse-region .collapse-container svg{
    margin: 0
}