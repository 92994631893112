.page-nav-bar-item {
  color: var(--page-nav-text);
}

.page-nav-bar-item-selected {
  color: var(--page-nav-selected-text-color);
  font-weight: bold;
}

.page-nav-bar-item:hover {
  color: var(--page-nav-hover-text);
  text-decoration: none;
}

.page-nav-bar-item:not(:last-child) {
  margin-right: 3.2rem;
}

.page-nav-bar-item-unselected {
  color: var(--page-nav-text);
  font-weight: normal;
}

.page-nav-bar-item-disabled {
  color: var(--disabled-text-color);
  pointer-events: none;
}
.page-nav-bar-item-hide {
  display: none;
}
