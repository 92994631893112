.responsive.dialog .MuiDialog-paperWidthSm {
  width: 88rem;
}

@media (max-width: 62rem) {
  .responsive.form .MuiDialog-paperWidthSm {
    width: 65rem;
  }

  .swap-reasons-menulist.dropdownsearchable-container {
    width: 25.2rem !important;
  }
}
