.app-load-fail {
  padding: 4rem 1.6rem 0 1.6rem;
}

.app-load-fail span {
  color: var(--page-container-text);
  font-size: 3.2rem;
  text-align: center;
  display: block;
}
