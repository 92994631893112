.chart-container {
  width: 100%;
}

.chart-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1.6rem;
}

.chart-column-md-4 {
  flex: 1;
  max-width: 48rem;
}

.chart-card {
  background-color: var(--secondary-page-container-background);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.4rem;
  box-shadow:
    0 1px 1px 0 rgb(60 75 100 / 14%),
    0 2px 1px -1px rgb(60 75 100 / 12%),
    0 1px 3px 0 rgb(60 75 100 / 20%);
}

.chart-card-header {
  border-bottom: 1px solid #d8dbe0;
  height: 4.8rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--page-container-text);
  line-height: 4.8rem;
  padding-left: 1.6rem;
}
