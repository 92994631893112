.flight-list-table-container {
  overflow-x: auto;
  height: 100%;
}

.legacy-filters .flight-list-table-container {
  max-height: calc(100vh - 21.4rem);
}

.new-modal-filters .flight-list-table-container {
  max-height: calc(100vh - 16.2rem);
}
.summary-panel-mini .new-modal-filters .flight-list-table-container {
  max-height: calc(100vh - var(--constant-table-view-height-summary-mini));
}
.summary-panel-expanded .new-modal-filters .flight-list-table-container {
  max-height: calc(100vh - var(--constant-table-view-height-summary-expanded));
}

/*when header is closed and summary panel is not available or hidden overriding height of bottom content in table view*/
.page-content.hide-header .new-modal-filters .flight-list-table-container {
  max-height: calc(100vh - var(--constant-table-bottom-content-height-hideheader-no-summary));
}

/*when header is closed and summary panel is collapsed overriding height of bottom content in table view*/
.page-content.hide-header .summary-panel-mini .new-modal-filters .flight-list-table-container {
  max-height: calc(100vh - var(--constant-table-bottom-content-height-hideheader-summary-mini));
}

/*when header is closed and summary panel is expanded overriding height of bottom content in table view*/
.page-content.hide-header .summary-panel-expanded .new-modal-filters .flight-list-table-container {
  max-height: calc(100vh - var(--constant-table-bottom-content-height-hideheader-summary-expanded));
}

.flight-list-table-container tr {
  border: 0;
  height: 4.4rem;
}

.flight-list-table-container thead tr {
  height: 4.2rem;
}

.flight-list-table-container th {
  border-bottom: 0;
  font-weight: bold;
  padding-top: 0;
  padding-bottom: 0;
  border-right: 1px solid var(--primary-border-color);
  padding-left: 2.4rem;
  /* This is to ensure the action menu goes behind the table headers on scroll */
  z-index: 1001;
}

.flight-list-table-container th svg {
  fill: #a6a6a6;
}

.flight-list-table-container td {
  padding-top: 0;
  padding-bottom: 0;
  border-right: 1px solid var(--primary-border-color);
  padding-left: 2.4rem;
}

.flight-list-table-container tr:not(:last-child) td {
  border-bottom: 0;
}

.flight-list-table-container tr:last-child td {
  border-bottom: 0;
}

.flight-list-warning-message {
  background-color: var(--flights-no-data-background);
  border: 0;
  color: var(--flights-no-data-text-color);
  padding: 0.8rem 1.6rem;
}

.flight-list-table-default-value {
  color: #353535;
}

.flight-list-table-container .secondary-label {
  color: #a6a6a6;
  font-weight: bold;
  font-size: 1.2rem;
}

.flight-list-table-container .secondary-label.zulu-label {
  text-transform: capitalize;
}

.flight-list-table-container span.time-diff-label-green::before,
span.time-diff-label-red:before {
  content: '\00a0';
}

.flight-list-table-container .td-alert {
  padding-left: 1.8rem;
  width: 1.875rem;
}

/* selected flight table row style */
.flight-list-table-container tr.selected td {
  background: var(--selected-flight-row-background);
  border-bottom: 1px solid var(--selected-flight-border);
  border-top: 1px solid var(--selected-flight-border);
}
.flight-list-table-container tr:hover {
  background: var(--selected-flight-row-background);
}

.grid-col-flight-table td.MuiTableCell-root,
.grid-col-flight-table th.MuiTableCell-root {
  padding-left: 1rem;
  padding-right: 1rem;
}

.grid-col-flight-table td.td-alert {
  padding-left: 1.6rem;
}

.flight-list-table-container .action-menu {
  display: flex;
  justify-content: center;
  width: 5rem;
}

/* for small screens */
@container (max-width: 992px) {
  .summary-panel-expanded .new-modal-filters .flight-list-table-container {
    max-height: calc(100vh - var(--constant-table-view-height-summary-expanded-portrait));
  }
}
