.header-container.navbar {
  background-color: var(--primary-header-background);
  font-weight: bold;
  height: 6rem;
  padding: 1.6rem;
  z-index: 1005;
}

.nav-bar-disable {
  color: var(--disabled-text-color);
  pointer-events: none;
}
.header-brand-container.navbar-brand {
  padding: 0;
  margin-right: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-brand-container .alaska-logo {
  display: block;
  width: 6.1rem;
}

.header-brand-container .alaska-logo span {
  display: inline-block;
  vertical-align: middle;
}

.alaska-logo path {
  fill: var(--alaska-logo-icon-color);
}

.header-brand-container .alaska-logo g#logo\/alaskaairlines {
  fill: var(--alaska-logo-icon-color);
}

.header-brand-container .app-title {
  color: var(--alaska-logo-text-color);
  line-height: 1rem;
}

.nav-bar-right-container {
  color: var(--page-nav-text);
  align-items: center;
  font-size: 1.2rem;
}

.nav-bar-center-container {
  background-color: var(--primary-header-center-background);
  align-items: center;
  font-size: 1.2rem;
  height: 6rem;
  padding: 1.6rem;
}

.nav-bar-username-role-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-bar-assignment-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: normal;
}

.nav-bar-assignment-edit,
.nav-bar-role-edit {
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
}

.nav-bar-assignment-edit:hover,
.nav-bar-role-edit:hover {
  color: var(--secondary-hover-color);
}

.nav-bar-user-info-container {
  margin-right: 1.6rem;
}

.nav-bar-env-info-container {
  align-items: center;
  margin-left: 10rem;
  margin-right: 10rem;
}

.nav-bar-info-container {
  color: var(--info-icon-color);
  align-items: center;
  font-size: 1.6rem;
  margin-right: 1rem;
}
