.view-window.single,
.view-window.swap-mode,
.view-window.maximized {
  border: none;
}

.view-window.multi:not(.maximized) {
  border: 2px solid var(--secondary-border-color);
}

.view-window-content {
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.view-window {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--darkerGray);
  background: var(--page-container-background);
}

.view-window.multi.focused:not(.maximized) {
  border: 2px solid var(--selected-flight-border);
}

.view-window.focused .resize-side {
  z-index: 1000;
}

.view-window.focused .resize-corner {
  z-index: 1001;
}

.resize-side,
.resize-corner {
  background-color: transparent;
  position: absolute;
}


#resize-left,
#resize-right {
  width: 1rem;
  cursor: col-resize;
  height: 100%;
  top: 0;
}

#resize-right {
  right: -0.5rem;
}

#resize-left {
  left: -0.5rem;
}

#resize-bottom,
#resize-top {
  height: 1rem;
  cursor: row-resize;
  width: 100%;
  left: 0;
}

#resize-bottom {
  bottom: -0.5rem;
}

#resize-top {
  top: -0.5rem;
}

.resize-corner {
  width: 1rem;
  height: 1rem;
}

#resize-bottom-right {
  cursor: nwse-resize;
  right: -0.5rem;
  bottom: -0.5rem;
}

#resize-top-left {
  cursor: nwse-resize;
  left: -0.5rem;
  top: -0.5rem;
}

#resize-bottom-left {
  cursor: nesw-resize;
  left: -0.5rem;
  bottom: -0.5rem;
}

#resize-top-right {
  cursor: nesw-resize;
  right: -0.5rem;
  top: -0.5rem;
}