.scaling-modal .modal-v2-footer-container.multi-buttons .modal-v2-footer-buttons,
.scaling-modal .modal-v2-footer-container.multi-buttons.modal-footer,
.dialog-actions-buttons {
  align-content: center;
}

.scaling-modal .modal-v2-footer-container.multi-buttons .scaling-modal-footer-button.clear .button,
.dialog-actions-buttons .scaling-modal-footer-button.clear .button {
  border: none;
  background-color: transparent;
  color: var(--interaction-link-color);
}

.dialog-actions-buttons .scaling-modal-footer-button.clear .button {
  width: 3.4rem;
  height: 1.7rem;
  font-size: 1.4rem;
}

.dialog.scalingmodalsize .MuiDialog-paperWidthSm {
  width: 47rem;
  height: 32.1rem;
}
.dialog-actions-buttons .scaling-modal-footer-button.apply:focus,
.scaling-modal .modal-v2-footer-container.multi-buttons .scaling-modal-footer-button.clear .button:focus {
  outline: 0.1rem solid var(--interaction-link-color);
}
.dialog-actions-buttons .scaling-modal-footer-button.apply .button {
  width: 12.4rem;
  height: 4.8rem;
}

.MuiDialog-root.dialog.scalingmodalsize .dialog-actions-buttons {
  padding-bottom: 2.4rem;
  display: flex;
  align-items: center;
  gap: 0.1rem;
  flex-direction: column;
}

.dialog-hr-top,
.dialog .dialog-hr-bottom {
  border-color: var(--secondary-border-color);
}
