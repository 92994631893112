.MuiFormControl-root.as-date-picker .MuiOutlinedInput-root {
    height: 4.4rem;
    width: 18.4rem;
    border-radius: 0.4rem;
}

.MuiDateCalendar-root
{
    height: 300px;
}

.MuiButtonBase-root.MuiPickersDay-root {
    color: rgba(255, 255, 255, 0.5);
}


.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin{
    font-size: 1rem;
    color: rgba(255, 255, 255, 1);
}

.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin:hover{
    background-color: rgba(255, 255, 255, 0.09);
}

.MuiDateCalendar-root .MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel {
    font-size: 1rem;
    font-weight: 800;
    color: rgba(255, 255, 255, 0.8);    
}

.MuiFormControl-root.as-date-picker .Mui-disabled .MuiSvgIcon-root {
    font-size: 1.8rem;
    color: var(--darkerGray);
}

.MuiFormControl-root.as-date-picker .MuiSvgIcon-root {
    font-size: 1.8rem;
    color: var(--primary-icon-color);
}

.MuiFormControl-root.as-date-picker .MuiOutlinedInput-input {
    padding-right: initial;
}

.Mui-disabled.start-date-picker, .Mui-disabled.end-date-picker {
    padding-right: initial;
    -webkit-text-fill-color: var(--disabled-text-color) !important;
    color: var(--disabled-text-color);
}

.MuiFormControl-root.as-date-picker .MuiInputAdornment-positionEnd {
    margin-left: 0.4rem;
}

.MuiFormControl-root.as-date-picker:not(.Mui-disabled).MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiFormControl-root.as-date-picker:not(.Mui-disabled) .MuiOutlinedInput-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--primary-icon-color);
}

.MuiFormControl-root.as-date-picker.Mui-disabled .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiFormControl-root.as-date-picker.Mui-disabled .MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline {
    -webkit-text-fill-color:var(--disabled-text-color);
}

.MuiFormControl-root.as-date-picker .MuiOutlinedInput-notchedOutline {
    border: 2px solid var(--secondary-border-color);
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background-color: var(--primary-button-background);
    border-radius: 50%;    
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:hover {
    background-color: #3f51b5;  
}

.MuiDateCalendar-root .MuiPickersCalendarHeader-label {
    font-size: 1.4rem;
    font-weight: 500;
}

.MuiDayCalendar-weekContainer .MuiButtonBase-root.Mui-disabled:not(.Mui-selected) {
    color: rgba(255, 255, 255, 0.5);
}

.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel {
    margin: 0 2px !important;
}

.as-date-picker .MuiOutlinedInput-input::selection {
    background-color: transparent;
}