.airport-code-input.form-control {
  font-size: var(--defaultFontSize);
  /* color: #000; */
  height: 4.4rem;
  border: 2px solid var(--secondary-border-color);
  background: transparent;
}

.airport-code-text-input {
  font-size: var(--defaultFontSize);
  color: var(--page-container-text);
  height: 4.4rem;
  border: 2px solid var(--secondary-border-color);
}

.airport-code-text-input,
.airport-code-text-input:focus {
  background: transparent !important;
  color: var(--page-container-text) !important;
}

.form-control:focus {
  border-color: var(--secondary-hover-color) !important;
}

.airport-code-input-dropdown.dropdown-menu {
  background-color: var(--dropdown-menu-background);
}

.airport-code-input-dropdown.dropdown-menu .dropdown-item {
  color: var(--page-container-text);
}

/* Removes the extra styling when tabbing down the menu list */
.airport-code-input-dropdown.dropdown-menu .dropdown-item:focus-visible,
.airport-code-input-dropdown.dropdown-menu .dropdown-item:hover {
  background-color: var(--primary-hover-color);
  border: none;
  outline: none;
}

.form-control:hover {
  border-color: var(--secondary-hover-color) !important;
}
.airport-code-input.dropdown:focus {
  border-color: var(--secondary-hover-color) !important;
}

.airport-code-input.dropdown:hover {
  border-color: var(--secondary-hover-color) !important;
}

.airport-code-input .airport-code .match,
.airport-code-input .no-results {
  font-weight: bold;
}

@media (max-width: 99.2em) {
  .airport-code-input .airport-code-input-dropdown.show {
    max-width: 36.8rem;
  }
}

@media (min-width: 99.3em) {
  .airport-code-input .airport-code-input-dropdown.show {
    max-width: 49.2rem;
  }
}

.airport-code-input .airport-code-input-dropdown.show.dropdown-left{
  max-height: 20rem;
  overflow-y: auto;
  border: 2px solid var(--secondary-border-color);
  border-radius: 0.3rem;
  font-size: var(--defaultFontSize);
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-top: 0.2rem !important;
  position: absolute;
  z-index: 1050;
  top: 100%;
  right: auto;
  left: 0;
}

.airport-code-input .airport-code-input-dropdown.show.dropdown-right {
  max-height: 20rem;
  overflow-y: auto;
  border: 2px solid var(--secondary-border-color);
  border-radius: 0.3rem;
  font-size: var(--defaultFontSize);
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-top: 0.2rem !important;
  position: absolute;
  z-index: 1050;
  top: 100%;
  right: 0;
  left: auto;
}

.airport-code-input .airport-code-input-dropdown::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
  border-radius: 0.5rem;
}

.airport-code-input .airport-code-input-dropdown::-webkit-scrollbar {
  background: var(--scrollbar-background);
  width: 0.5rem;
  height: 0.5rem;
}

.airport-code-input .airport-code-input-dropdown::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-background);
  border-radius: 0.5rem;
}

.airport-code-input .dropdown-item {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
