.checkbox-container .checkbox-icon {
  margin-right: 1rem;
}

/* sizing of checkbox & radio icon */
.checkbox-container .checkbox-icon.checkbox-type,
.checkbox-container .checkbox-icon.radio-type {
  font-size: 1.6rem;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container .checked {
  color: var(--primary-icon-color);
}

.checkbox-container .unchecked {
  color: var(--primary-icon-color);
}

.checkbox-container .disable {
  pointer-events: none;
  cursor: default;
  color: var(--primary-border-color);
}
