.chipinputdropdown-paper .MuiList-root::-webkit-scrollbar {
  width: 1.2rem;
}
.filter-origin.chipsinputdropdown-container::-webkit-scrollbar,
.filter-destination.chipsinputdropdown-container::-webkit-scrollbar,
.MuiInputBase-root.filter-aircraft::-webkit-scrollbar,
.MuiInputBase-root.filter-flightnumber::-webkit-scrollbar {
  width: 1rem;
}

/* scrollbar - track */
.chipinputdropdown-paper .MuiList-root::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}

/* scrollbar - thumb */
.chipinputdropdown-paper .MuiList-root::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-background);
  border-radius: 01.2rem;
  border: 0.3rem solid var(--scrollbar-background);
}

.chipinputdropdown-paper .MuiList-root {
  border: 0.2rem solid var(--secondary-border-color);
  max-height: 18rem !important;
}

.filter-origin.chipsinputdropdown-container:hover,
.filter-destination.chipsinputdropdown-container:hover,
.MuiInputBase-root.filter-flightnumber:hover,
.MuiInputBase-root.filter-aircraft:hover {
  border: 0.2rem solid var(--interaction-link-color);
}

.filter-origin.chipsinputdropdown-container,
.filter-destination.chipsinputdropdown-container {
  border: 0.2rem solid var(--secondary-border-color);
  padding-bottom: 1rem;
  padding-top: 0.26rem;
  border-radius: 0.3rem;
  overflow-y: auto;
}
.filter-origin.chipsinputdropdown-container,
.filter-destination.chipsinputdropdown-container {
  max-height: 8rem;
}
