.equipment-tool-modal.dialog .MuiDialog-paperWidthSm {
  width: 80rem;
}
.equipment-tool-modal .dialog.thin .MuiDialog-paperWidthSm {
  min-height: 29rem;
}

.equipment-tool-modal .MuiDialogContent-root {
  overflow-y: hidden;
}

.equipment-change-table-container tr,
.equipment-change-details-certificates-satcom-container,
.equipment-change-details-container,
.MuiTable-root.equipment-change-table thead tr,
.equipment-tool-action-buttons {
  display: flex;
}

.MuiTable-root.equipment-change-table {
  display: table;
}

.equipment-change-details-container {
  align-items: center;
  flex-wrap: wrap;
}

.equipment-tool-modal .dialog-actions-container {
  display: none;
}

.equipment-change-details-container .MuiGrid-item {
  width: 50%;
  padding-bottom: 2.4rem;
}

.equipment-change-details-certificates-satcom-container .equipment-change-details {
  width: 100%;
}

.equipment-change-details-items-container {
  display: flex;
  flex-wrap: wrap;
}

.MuiTable-root.equipment-change-table thead {
  width: 100%;
}

.MuiTable-root.equipment-change-table tbody {
  display: block;
  width: 100%;
  position: relative;
  overflow-y: auto;
}

.MuiTable-root.equipment-change-table thead tr {
  width: 100%;
}

.MuiTable-root.equipment-change-table td,
.MuiTable-root.equipment-change-table th {
  flex-basis: 100%;
  flex-grow: 2;
  display: block;
  padding: 1rem;
  text-align: left;
}

.equipment-tool-modal-form .dropdownsearchable-container,
.equipment-tool-modal-form .textinput,
.equipment-tool-modal-form .dropdownsearchable-paper .menulist {
  width: 39.2rem !important;
}

.equipment-change-table-container .MuiTableCell-root {
  border: none;
}

.equipment-tool-action-buttons {
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.button-container.acknowledge-flight-btn,
.button-container.remove-flight-ack-btn {
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
}

.equipment-tool-action-buttons .button.button-primary {
  width: 16.5rem;
}

.equipment-change-details svg {
  margin-right: 1rem;
}

.cetificates-header,
.satcom-header,
.equipment-change-details-container {
  margin-bottom: 1.6rem;
}

.equipment-change-details-container .icon {
  font-size: 1.6rem;
}

.equipment-change-details .label {
  margin-bottom: 0.2rem;
}

.equipment-change-certificate-item {
  width: 50%;
  display: flex;
  margin-bottom: 1.6rem;
}

.equipment-change-table-container .MuiTableRow-root:nth-of-type(even),
.equipment-change-table-container .equipment-change-table-head .MuiTableRow-root.MuiTableRow-head {
  background-color: var(--equipment-table-stripe);
}

.equipment-change-table-container .MuiTableRow-root:nth-of-type(odd) {
  background-color: transparent;
}

.equipment-change-table-container .MuiTableCell-root.MuiTableCell-head {
  background-color: transparent;
}
.equipment-change-table-container .equipment-change-table-head.reference-head {
  background-color: var(--equipment-table-header);
}

.equipment-change-table-container .equipment-change-table-body {
  overflow-y: auto;
  max-height: 40rem;
  overflow-x: hidden;
}

@media (max-width: 62rem) {
  .dialog.responsive.equipment-tool-modal .MuiDialog-paper.MuiDialog-paperWidthSm {
    max-width: 50rem;
  }
}

.equipment-tool-modal-form-section-separator {
  border-bottom: 1.5px solid var(--primary-border-color);
  height: 0.1rem;
  margin-bottom: 1.5rem;
}
