.crosshair-icon {
  font-size: 2rem;
  color: var(--flight-nav-bar-icon-fill);
  background-color: var(--flight-nav-bar-icon-background);
}

.crosshair-button-container {
  border-radius: 0.6rem;
  cursor: pointer;
  width: 3.4rem;
  height: 3rem;
  box-shadow: var(--flight-nav-bar-item-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--flight-nav-bar-icon-background);
  margin-right: -0.15rem;
}

.crosshair-button-inactive-mode .crosshair-icon,
.crosshair-button-container.crosshair-button-inactive-mode {
  color: var(--flight-nav-bar-icon-fill);
  background-color: var(--flight-nav-bar-icon-background);
}

.crosshair-button-active-mode .crosshair-icon,
.crosshair-button-container.crosshair-button-active-mode {
  color: var(--flight-nav-bar-icon-background);
  background-color: var(--gantt-flight-toggle-selected-background);
}
