.multi-dropdown-flight-phase {
  display: flex;
  align-items: center;
  flex: auto;
}
.multisel-dropdown-container
  .MuiInputBase-root.MuiInput-root.MuiInput-underline
  .MuiInputBase-formControl
  .MuiInput-formControl {
  width: 100%;
  margin-bottom: 1.6rem;
}

.multisel-dropdown-container label.MuiInputLabel-formControl {
  transform: translate(0, 1.2rem) scale(1);
}

/* dropdown arrow styling */
.multisel-dropdown-container .MuiSelect-icon {
  color: var(--primary-icon-color);
  margin: 0.5rem 1.159rem;
}

.Mui-disabled > .MuiSelect-icon {
  color: gray;
  margin: 0.5rem 1.159rem;
}

.multisel-dropdown-container .MuiSelect-select.MuiSelect-select {
  padding: 1.5rem 2.5rem 1.4rem 1rem !important;
}

.MuiPopover-paper::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background: #f1f1f1;
}
.MuiPopover-paper::-webkit-scrollbar {
  width: 0.5rem;
  background: #f1f1f1;
}
.MuiPopover-paper::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #a6a6a6;
}

.multisel-dropdown-container .Mui-focused {
  border: 2px solid var(--interaction-link-color);
  height: 4.4rem;
}
