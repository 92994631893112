.flightInfo-table .MuiTableRow-root:nth-of-type(even) {
  background-color: var(--page-container-background);
}

.flightInfo-table table thead tr th, .flightInfo-table table tbody tr td{
  padding: 2px 0!important;
}

.flightInfo-table .trip-info {
margin-top: 5px;
}
.flightInfo-table .no-matching-trips {
  margin-top: 1rem;
}