.flight-puck-container > .departure-indicator {
  border: var(--puck-early-dealy-indicator-border) solid;
  position: absolute !important;
  z-index: 1;
}

.flight-puck-container > .arrival-indicator {
  border: var(--puck-early-dealy-indicator-border) solid;
  position: absolute !important;
}

.flight-puck-container > .early {
  border-color: var(--puck-early-indicator);
  background-color: var(--puck-early-indicator);
}

.flight-puck-container > .delay {
  border-color: var(--puck-delay-indicator);
  background-color: var(--puck-delay-indicator);
  transform: rotate(180deg) !important;
  transform-origin: left center !important;
}

/*Since puck has rounded corners, moving departure-delay indicator to the right by 1px*/
.flight-puck-container > .departure-indicator.delay {
  margin-left: 0.1rem;
}

/*Since puck has rounded corners, moving arrival-early indicator to the left by 1px*/
.flight-puck-container > .arrival-indicator.early {
  margin-left: -0.1rem;
}
