.configuration {
  height: calc(100vh - 6rem);
}
.configuration .page-header {
  height: 6rem;
  border-bottom: 2px solid var(--gantt-chart-border-color);
  border-top: 2px solid var(--gantt-chart-border-color);
  display: flex;
}
.configuration .page-header div {
  padding-top: 1.4rem;
  padding-left: 6rem;
  font-size: 1.8rem;
}
.configuration .page-header div.title {
  flex: 3 1;
}
.configuration .page-header div.new-window-switch {
  flex: 1 3;
}
.configuration-table-container {
  padding-left: 4rem;
  padding-top: 1.5rem;
  margin-left:0px;
  overflow-y: scroll;
  height: calc(100% - 5.9rem);
}
.configuration .config-file:hover {
  color: var(--brightBlue);
  cursor: pointer;
}
.configuration .config-file {
  max-width: 30%;
}
.configuration .MuiTableRow-root:nth-of-type(odd) {
  background: var(--primary-container-background);
}
.configuration .MuiTableCell-root,
.configuration .MuiTableContainer-root {
  border: none;
  background: var(--primary-container-background);
  box-shadow: none;
}
.config-trash-icon {
  cursor: pointer;
}
.configuration-table-body .modified-time {
  padding-left: 2rem;
}

.configuration-table-container .th-table-header-cell {
  font-weight: bold;
}

.error-icon {
  display: inline;
  padding: 15px;
  margin: 5px;
  margin-right: 8px;
}
