.flight-pane-container {
  position: relative;
  z-index: 1510;
  background-color: var(--secondary-page-container-background);
  height: 100%;
  border-color: var(--primary-border-color);
  border-style: solid;
  border-width: 0px 0px 0px 2px;
}

.flight-pane-header {
  border-bottom: 2px solid var(--primary-border-color);
  font-size: 1.6rem;
  font-weight: bold;
  height: 4.2rem;
  line-height: 4.2rem;
  padding-left: 2.4rem;
  display: flex;
  justify-content: space-between;
}

.flight-pane-header-right {
  margin-right: 1rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.flight-pane-header-right .svg-inline--fa {
  vertical-align: top;
  color: #d8d8d8d3;
}

.close-detail-pane:hover {
  color: var(--interaction-link-color);
}

.flight-pane-content-tab .nav .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--interaction-link-color) !important;
  border-color: #dee2e6 #dee2e6 #fff;
}

.flight-pane-content-tab .nav .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  color: var(--interaction-link-color);
}

.flight-pane-content-tab .nav-link {
  display: block;
  padding: 0.9rem 0.9rem 0 0.9rem;
}

.flight-pane-container .flight-pane-content-tab a {
  color: var(--unfocused-interaction-link-color);
  font-weight: bold;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.flight-pane-content-tab .tab-content {
  padding-top: 1rem;
  padding-left: 2.4rem;
  overflow-y: auto;
}

.activity-log-tab-container .tab-content {
  overflow-y: auto;
}


.new-modal-filters .flight-pane-content-tab .tab-content {
  max-height: calc(100vh - 20.4rem);
}

.page-content.hide-header .new-modal-filters .flight-pane-content-tab .tab-content {
  max-height: calc(100vh - 16.2rem);
}

.page-content.show-header .flights-container.summary-panel-mini .new-modal-filters .flight-pane-content-tab .tab-content {
  max-height: calc(100vh - 22.4rem);
}

.page-content.show-header .flights-container.summary-panel-expanded .new-modal-filters .flight-pane-content-tab .tab-content {
  max-height: calc(100vh - 52.4rem);
}

.page-content.hide-header .flights-container.summary-panel-expanded .new-modal-filters .flight-pane-content-tab .tab-content {
  max-height: calc(100vh - 45.4rem);
}

.new-modal-filters .activity-log-tab-container .tab-content {
  max-height: calc(100vh - 20.4rem);
}

.legacy-filters .flight-pane-content-tab .tab-content {
  max-height: calc(100vh - 25.5rem);
}

.flight-pane-container .flight-pane-content-tab .nav-tabs {
  height: 4.2rem;
  padding-left: 1.7rem !important;
  border-bottom: 2px solid var(--primary-border-color);
}

.flight-pane-container .flight-pane-content-tab .nav-tabs .nav-link.active {
  border-bottom: 1px solid var(--primary-border-color);
  background-color: var(--secondary-page-container-background);
}

.flight-pane-container .flight-pane-content-tab .nav-tabs .nav-link {
  border: none;
}

/* CSS for transitions */
.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5000ms linear 5000ms;
}

/* exit */
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 5000ms linear;
}
.fade-exit-done {
  opacity: 0;
}

/* slide enter */
.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateY(0.5rem);
  z-index: 1;
}
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition:
    opacity 3000ms linear 1000ms,
    transform 3000ms ease-in-out 1000ms;
}

/* slide exit */
.slide-exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}
.slide-exit.slide-exit-active {
  opacity: 0;
  transform: scale(0.97) translateY(0.5rem);
  transition:
    opacity 1500ms linear,
    transform 1500ms ease-out;
}
.slide-exit-done {
  opacity: 0;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.flight-pane-header-left span.pane-header-text-separator {
  border-left: 2px solid #d9d9d9;
  display: inline-block;
  margin: 0 1rem;
  height: 1.3rem;
}

.section-header {
  display: flex;
}

.section-header-right {
  font-weight: normal;
}
.flight-header-row span.pane-header-text-separator {
  border-left: 2px solid #d9d9d9;
  display: inline-block;
  margin: 0 1rem;
  height: 1.3rem;
  padding-top: 0.8rem;
  margin-top: 0.5rem;
}
.flight-pane-header-left img {
  margin-bottom: 0.3rem;
}
