.collapse-region .collapse-chevron {
    height: 1.6rem !important;
    width: 1.6rem !important;
    margin-top: 1.3rem;
  }

.collapse-region .collapse-container {
    color: var(--primary-icon-color);
    cursor: pointer;
    text-align: right;
}

.collapse-region .collapse-container.collapse-container-disabled, .collapse-region .collapse-container.collapse-container-disabled svg{
    color:  var(--gray);
    cursor: not-allowed;
}

.collapse-container svg, .collapse-container [role="button"]{
   z-index: 1;
   position: relative;
}