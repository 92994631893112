.flight-list-table-container-header {
  border-bottom: var(--flight-list-table-date-border);
  background-color: var(--flight-list-table-date-background);
  color: var(--page-container-text);
  font-size: 1.6rem;
  font-weight: bold;
  height: 4.2rem;
  line-height: 4.2rem;
  padding-left: 1.6rem;
  display: flex;
  padding-right: 1.6rem;
}