.swap-warning-modal-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.8rem;
}

.swap-warning-form .dialog-actions-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1rem;
}

.swap-warning-modal-cancel-button{
  color: var(--primary-button-disabled-background);
  background-color: var(--dark-secondary);
  border-radius: 0.4rem;
}

.swap-warning-modal-certificates-container {
  width: 100%;
}

.swap-warning-modal-items-container {
  display: flex;
  flex-wrap: wrap;
}

.swap-warning-modal-warningmessage-header {
  font-size: 1.2rem;
  padding-bottom: 1rem;
}

.swap-warning-modal-messages-container {
  padding-bottom: 1rem;
}

.swap-warning-modal-certificate-item svg {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.swap-warning-modal-details-container {
  margin-bottom: 1.6rem;
}

.swap-warning-modal-certificate-item .label {
  font-size: 1.2rem;
  font-weight: 400;
}

.swap-warning-modal-certificate-item {
  width: 40%;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}

.sectionHeader {
  font-size: var(--defaultFontSize);
  font-weight: 800;
  color: var(--page-container-text);
}

.headerGrid {
  padding-bottom: .6rem;
  padding-top: 1rem;
}

.sectionSeparator {
  border-bottom: 1.5px solid var(--primary-border-color);
  height: 0.1rem;
  margin-bottom: 1.5rem;
}