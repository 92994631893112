/* Regular */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Regular.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

/* Regular Italic */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Italic.ttf') format('truetype');
  font-display: swap;
  font-style: italic;
  font-weight: normal;
}

/* Bold */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Bold.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: bold;
}

/* Bold Italic */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-display: swap;
  font-style: italic;
  font-weight: bold;
}

/* Black */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Black.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}

/* Black Italic */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-BlackItalic.ttf') format('truetype');
  font-display: swap;
  font-style: italic;
  font-weight: 900;
}

/* Light */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Light.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

/* Light Italic */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-LightItalic.ttf') format('truetype');
  font-display: swap;
  font-style: italic;
  font-weight: 300;
}

/* Thin */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Thin.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 100;
}

/* Thin Italic */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-ThinItalic.ttf') format('truetype');
  font-display: swap;
  font-style: italic;
  font-weight: 100;
}
