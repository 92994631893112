.session-timeout-btn .button {
  width: 13rem !important;
}
.session-timeout-modal .modal-v2-footer-buttons {
  margin-bottom: -1.2rem;
}
.session-timeout-modal .modal-body {
  padding: 1.8rem 3.6rem 1.8rem 3.6rem !important;
}

.session-timeout-modal .session-time-out-div {
  font-weight: 600;
}
