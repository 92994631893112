/* entire modal dialog styles */
.dialog .MuiDialog-container .MuiDialog-paper {
  border: 0;
  border-radius: 1.2rem;
}
.dialog .MuiDialogContent-root {
  padding: 2.4rem 2.5rem 2.4rem 3.6rem !important;
}

/* Dialog title change style  */
.dialog .MuiDialogTitle-root {
  padding-bottom: 0rem !important;
}
.dialog-title-container .MuiTypography-h6 {
  font-size: 2.2rem;
  font-weight: 800;
  padding: 1.1rem 1.3rem 0 1.2rem;
}
.draggable-dialog-title-icon {
  cursor: move;
}

/* Dialog close icon button styles */
.dialog .modal-close {
  color: var(--gray);
  font-size: 2.4rem;
  cursor: pointer;
  position: absolute;
  top: 1.6rem;
  right: 2rem;
}

.dialog .modal-close:hover {
  color: var(--secondary-hover-color);
}

/* Top horizontal line */
.dialog .dialog-hr-top {
  margin-top: 0.8rem;
}
/* bottom  horizontal line */
.dialog .dialog-hr-bottom {
  padding-bottom: 2.4rem;
  align-items: center;
  justify-content: space-between;
}

/* Dialog action css */
.dialog .dialog-actions-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* Dialog action multi-buttons css  */
.dialog .dialog-actions-container.wide.multi-buttons {
  padding: 0rem 0rem 1rem 0rem !important;
}
.dialog .dialog-actions-container.thin.multi-buttons {
  padding: 0rem 3.6rem 1rem 3.6rem;
}
.dialog .MuiDialogActions-spacing > :not(:first-child) {
  margin-left: 0rem;
}

/* bottom horizontal line - wide */
.dialog .dialog-actions-container.wide {
  padding: 0rem 0rem 3.4rem 0rem;
}
/* bottom horizontal line - thin */
.dialog .dialog-actions-container.thin {
  padding: 0rem 3.6rem 3.4rem 3.6rem;
}

/* modal body scrollbar styles */
.dialog .MuiDialogContent-root::-webkit-scrollbar {
  width: 1.2rem;
}

/* scrollbar - track */
.dialog .MuiDialogContent-root::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}

/* scrollbar - thumb */
.dialog .MuiDialogContent-root::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-background);
  border-radius: 1.2rem;
  border: 0.3rem solid var(--scrollbar-background);
}

.dialog.thin .MuiDialog-paperWidthSm {
  width: 46.4rem;
}
.dialog.wide .MuiDialog-paperWidthSm {
  max-width: 88rem;
}

/* responsive widths for entire modal */
/* responsive behavior for the modal body content should be handled outside of this generic modal component */
/* @media uses the browser font-size 16px, ignores our overidden :root font-size 10px */
@media (max-width: 62em) {
  .dialog.responsive .MuiDialog-paperWidthSm {
    max-width: 46.4rem;
  }
}

@media (min-width: 62.0625em) {
  .dialog.responsive .MuiDialog-paperWidthSm {
    max-width: 88rem;
  }
}
