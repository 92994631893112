.new-filter-list.flight-list-filters {
  display: block;
}

.MuiInputBase-root.filter-flightnumber,
.MuiInputBase-root.filter-aircraft {
  height: 8.8rem;
  border: 0.2rem solid var(--secondary-border-color);
  padding-bottom: 1rem;
  padding-top: 0.26rem;
  border-radius: 0.3rem;
  overflow-y: auto;
}

.new-filter-list .MuiGrid-item {
  padding-top: 0.6rem !important;
}

.alert-filter .MuiInputBase-root.Mui-disabled {
  background-color: var(--primary-border-color);
}

.alert-brick-container-title .MuiSelect-icon {
  color: var(--disabled-text-color);
}
