.activity-log-detail-date {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  padding-top: 2rem;
  padding-bottom: 0.2rem;
  position: sticky;
  top: -1rem;
  z-index: 9999;
  background-color: var(--secondary-page-container-background);
}
