.flight-puck-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 10;
}

.swap-flight-puck {
  background: var(--puck-swap-flight-background);
}

.flight-puck {
  background: #b3b3b3;
  border-radius: var(--puck-border-radius);
  box-shadow: var(--puck-box-shadow);
  color: var(--puck-label-color);
  display: inline-flex;
  font-size: var(--flight-number-font-size);
  font-weight: 800;
  height: var(--puck-height);
  line-height: var(--puck-line-height);
  text-align: center;
  align-content: center;
  position: relative;
}
.flight-puck.taxi-block.equipmentchange-flight,
.flight-puck.taxi-block.equipmentchange-flight:hover .short-puck-flag {
  background: var(--puck-phase-eqip);
}
.flight-puck.taxi-block.phase-pre-flight.watch-flight,
.flight-puck.taxi-block.phase-in.watch-flight,
.flight-puck.taxi-block.phase-in-only.watch-flight,
.flight-puck.taxi-block.phase-on-in-only.watch-flight,
.flight-puck.taxi-block.phase-pre-flight.watch-flight:hover .short-puck-flag,
.flight-puck.taxi-block.phase-in.watch-flight:hover .short-puck-flag,
.flight-puck.taxi-block.phase-in-only.watch-flight:hover .short-puck-flag,
.flight-puck.taxi-block.phase-on-in-only.watch-flight:hover .short-puck-flag {
  background: var(--puck-phase-watch) !important;
}

.flight-puck.taxi-block.phase-pre-flight.infoby-flight,
.flight-puck.taxi-block.phase-pre-flight.infoby-flight:hover .short-puck-flag {
  background: var(--dark-puck-phase-infoby) !important;
}

.flight-puck.taxi-block.phase-pre-flight,
.flight-puck.taxi-block.phase-out,
.flight-puck.taxi-block.phase-in,
.flight-puck.taxi-block.phase-on-only,
.flight-puck.taxi-block.phase-in-only,
.flight-puck.taxi-block.phase-out-on-only,
.flight-puck.taxi-block.phase-on-in-only,
.flight-puck.taxi-block.phase-pre-flight:hover .short-puck-flag,
.flight-puck.taxi-block.phase-out:hover .short-puck-flag,
.flight-puck.taxi-block.phase-in:hover .short-puck-flag,
.flight-puck.taxi-block.phase-on-only:hover .short-puck-flag,
.flight-puck.taxi-block.phase-in-only:hover .short-puck-flag,
.flight-puck.taxi-block.phase-out-on-only:hover .short-puck-flag,
.flight-puck.taxi-block.phase-on-in-only:hover .short-puck-flag {
  background: var(--puck-phase-default-background);
}

.flight-puck.taxi-block.phase-pre-flight {
  background-color: var(--puck-phase-planned);
}

.flight-puck.taxi-block.phase-off,
.flight-puck.taxi-block.phase-on,
.flight-puck.taxi-block.phase-off-only,
.flight-puck.taxi-block.phase-off-on-only,
.flight-puck.taxi-block.phase-off:hover .short-puck-flag,
.flight-puck.taxi-block.phase-on:hover .short-puck-flag,
.flight-puck.taxi-block.phase-off-only:hover .short-puck-flag,
.flight-puck.taxi-block.phase-off-on-only:hover .short-puck-flag {
  background: var(--puck-phase-highlighted-background);
}

.flight-puck.taxi-block.phase-return-to-gate,
.flight-puck.taxi-block.phase-return-to-gate:hover .short-puck-flag {
  background: var(--puck-block-default-background);
}

.flightpuck-container .flight-puck.taxi-block.equipmentchange-flight:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-eqip-transparent);
}

.flightpuck-container .flight-puck.taxi-block.phase-pre-flight.watch-flight:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .flight-puck.taxi-block.phase-in.watch-flight:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .flight-puck.taxi-block.phase-in-only.watch-flight:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .flight-puck.taxi-block.phase-on-in-only.watch-flight:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-watch-transparent);
}

.flightpuck-container .flight-puck.taxi-block.phase-pre-flight:hover .flight-action-menu.short-puck-flag,
.flight-puck.taxi-block.phase-out:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .flight-puck.taxi-block.phase-in:hover .flight-action-menu.short-puck-flag,
.flight-puck.taxi-block.phase-on-only:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .flight-puck.taxi-block.phase-in-only:hover .flight-action-menu.short-puck-flag,
.flight-puck.taxi-block.phase-out-on-only:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .flight-puck.taxi-block.phase-on-in-only:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-default-background-transparent);
}

.flightpuck-container .flight-puck.taxi-block.phase-return-to-gate:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-block-default-background-transparent);
}

.flightpuck-container .flight-puck.taxi-block.phase-pre-flight:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-planned-transparent);
}

.flightpuck-container .flight-puck.taxi-block.phase-off:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .flight-puck.taxi-block.phase-on:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .flight-puck.taxi-block.phase-off-only:hover .flight-action-menu.short-puck-flag,
.flightpuck-container .flight-puck.taxi-block.phase-off-on-only:hover .flight-action-menu.short-puck-flag {
  background: var(--puck-phase-highlighted-background-transparent);
}

/* selected flight puck style */
.flight-puck.selected.phase-pre-flight,
.flight-puck.selected.phase-out,
.flight-puck.selected.phase-off,
.flight-puck.selected.phase-on,
.flight-puck.selected.phase-in,
.flight-puck.selected.phase-return-to-gate,
.flight-puck.selected.phase-off-only,
.flight-puck.selected.phase-on-only,
.flight-puck.selected.phase-in-only,
.flight-puck.selected.phase-off-on-only,
.flight-puck.selected.phase-out-on-only,
.flight-puck.selected.phase-on-in-only {
  background: var(--selected-flight-puck-background);
  border: 1px solid var(--selected-flight-border);
  color: black;
  border-radius: var(--puck-border-radius);
  line-height: var(--puck-line-height-selected);
}

/* select canceled flight puck style */
.flight-puck.selected.cancelled,
.flight-puck.selected.taxi-block.cancelled {
  /* repeating 1px lines at 45 degrees */
  background: repeating-linear-gradient(
    -45deg,
    var(--selected-flight-puck-background),
    var(--selected-flight-puck-background) 9px,
    #b3b3b3 9px,
    #b3b3b3 10px
  );
  border: 1px solid var(--selected-flight-border);
  color: black;
}

/* canceled flight puck style */
.flight-puck.cancelled,
.flight-puck.taxi-block.cancelled,
.flight-puck.cancelled.taxi-block.infoby-flight,
.flight-puck.cancelled.infoby-flight {
  /* repeating 1px lines at 45 degrees */
  background: repeating-linear-gradient(
    -45deg,
    #b3b3b3,
    #b3b3b3 9px,
    var(--darkerGray) 9px,
    var(--darkerGray) 10px
  ) !important;
}

.flight-puck > .flight-number {
  height: inherit;
  vertical-align: top;
  width: inherit;
}

.flight-puck > .left-block {
  height: inherit;
  border-right: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.flight-puck > .right-block {
  height: inherit;
  border-left: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.flight-puck > .left-block.highlight,
.flight-puck > .right-block.highlight {
  background: var(--puck-block-highlighted-background);
}

.flight-puck > .left-block.default,
.flight-puck > .right-block.default {
  background: var(--puck-block-default-background);
}

.flight-puck.selected > .left-block,
.flight-puck.selected > .right-block {
  border: 0;
  height: var(--selected-puck-early-delay-indicator-height);
}

.flightpuck-container.short-puck-flag .flight-puck {
  display: flex;
  justify-content: center;
}

.flightpuck-container .flight-puck {
  justify-content: end;
}

.flight-puck > .flight-action-menu {
  position: absolute;
  cursor: pointer;
  top: var(--flight-action-menu-top);
  z-index: 99;
  font-size: var(--flight-action-menu-icon-size);
  height: var(--flight-action-menu-height);
}

.flight-puck > .flight-action-menu.short-puck-flag {
  border-radius: 0.2rem;
}

.flight-puck .flight-action-menu .action-menu {
  position: relative;
  display: flex;
  align-items: center;
  visibility: hidden;
  height: var(--puck-height);
  bottom: var(--flight-action-menu-bottom);
  padding: 0.4rem 0.4rem;
}

.flight-puck:hover .flight-action-menu .action-menu {
  visibility: visible;
}

.flight-puck.cancelled {
  z-index: 100;
}

.flight-puck.selectable:hover {
  cursor: pointer;
}

.flight-puck-container > .alert-puck-item > .MuiBadge-root {
  vertical-align: sub;
}

.swap-mode-active .flight-puck {
  user-select: none;
}
