.flightpuck-container .taxi-block.phase-in:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-in-only:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-pre-flight:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-pre-flight-only:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-off:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-off-only:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-on:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-on:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-out:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-out-only:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container .taxi-block.phase-return-to-gate:hover .group-puck-container > div:not(.flight-action-menu),
.flightpuck-container.selected-puck.visible-overlay .flight-number {
  opacity: 0.5;
}

.flightpuck-container {
  display: flex;
}

.flightpuck-container.selected-puck {
  position: relative;
  border: 2px solid var(--puck-select-outline);
  border-radius: 4px;
  align-items: center;
  left: var(--select-puck-border-left);
  background: var(--puck-select-background);
  height: var(--select-puck-border-height);
  padding-left: var(--select-puck-border-padding);
  padding-right: var(--select-puck-border-padding);
}

.flightpuck-container.mx-message-indicator-puck {
  position: relative;
  border: 2px solid var(--warning-color);
  border-radius: 1.14px;
  align-items: center;
  background: var(--puck-select-background);
}

.flightpuck-container.enhanced-scaling-selected-puck {
  position: relative;
  border: 2px solid var(--puck-select-outline);
  border-radius: 4px;
  align-items: center;
  background: var(--puck-select-background);
  z-index: 99;
}

.flightpuck-container.selected-puck.short-puck-flag {
  left: var(--select-short-puck-border-left);
  padding-left: var(--select-short-puck-border-padding);
  padding-right: var(--select-short-puck-border-padding);
}

.arrival-indicator.selected-puck {
  position: relative;
  left: var(--select-puck-early-arrival-left);
}
