.scaling-button-container {
    border-radius: 0.6rem;
    padding-top: 0.25rem;
    width: 3.3rem;
    height: 3rem;
    margin-left: 0.6rem;
    text-align: center;
    box-shadow: var(--flight-nav-bar-item-shadow);
    cursor: pointer;
    background-color: var(--flight-nav-bar-icon-background);
    color:#81caff;
  }
  .scaling-button-container svg.scaling-icon {
    height: 23px;
    width:23px;
  }