.assignment-modal.modal-container .form-item:not(:last-child),
.form-title {
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
}

.assignment-modal .assignment-session-form .form-item:not(:last-child) {
  margin-bottom: 1rem;
}

.assignment-session-form {
  line-height: 3rem;
}
