.unauthorized {
  padding: 4rem 1.6rem 0 1.6rem;
}

.unauthorized span {
  color: var(--page-container-text);
  /* TODO ask Jeanee */
  font-size: 3.2rem;
  text-align: center;
  display: block;
}
