.timeline-container {
  overflow-x: hidden;
  display: flex;
  flex: 1;
}

.timeline-inner-container {
  display: flex;
  flex-direction: column;
  background-color: var(--gantt-chart-timeline-background);
}

.timeline-timezone-label {
  background: var(--gantt-chart-tailnumbers-background);
  border-bottom: var(--gantt-row-line-weight) solid var(--gantt-chart-border-color);
  border-right: var(--gantt-row-line-weight) solid var(--gantt-chart-border-color);
  width: var(--aircraft-container-width);
  text-align: center;
  font-weight: 700;
  font-size: var(--constant-flight-number-font-size);
  height: var(--constant-timeline-axis-height);
  line-height: var(--constant-timeline-axis-height);
}

.timeline-timezone-label.tail-number-indicators {
  width: var(--aircraft-container-width-with-indicators);
  text-align: center;
  padding: var(--aircraft-container-padding);
}

.timeline-timezone-label-container, .timeline-container {
  z-index: 1003;
}
