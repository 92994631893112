:root,
:root.modern-dark-theme,
:root.classic-theme {
  /* This converts 1rem from 16px to 10px. 16px * 0.625 = 10px */
  font-size: 62.5%;

  /* Shared variables that will be the same across both light and dark mode */
  --defaultFontFamily: 'Lato', 'Helvetica', sans-serif;

  /* Base font size: 1.4rem = 14px */
  --defaultFontSize: 1.4rem;
}

:root {
  /* Light color palette */

  /* TODO delete dark blues after converting all modals to v2 */
  --darkestBlue: #0b334f;
  --darkerBlue: #083250;
  --darkBlue: #01426a;
  --lightBlack: #212223;
  --lightGray: #eeeeee;
  --gray: #d9d9d9;
  --darkerGray: #979797;
  --brightBlue: #0074c8; /* interaction blue */
  --lightBlue: #cce3f4;
  --lightestBlue: #e5f1f9;
  --darkRed: #df0b37;

  /******** Web page parts ********/

  /* Icons */
  --primary-icon-color: var(--brightBlue);
  --secondary-icon-color: var(--gray);
  --info-icon-color: var(#ffffff);

  /* Borders */
  --primary-border-color: var(--lightGray);
  --secondary-border-color: var(--lightGray);

  /* Hover */
  --primary-hover-color: var(--lightGray);
  --secondary-hover-color: var(--brightBlue);

  /* Links */
  --interaction-link-color: var(--brightBlue);
  --unfocused-interaction-link-color: black;
  --access-menu-link-color: black;

  /* primary header is the top most header of the web app */
  --primary-header-background: white;
  --primary-header-center-background: #e7e7e7;
  --alaska-logo-icon-color: var(--darkBlue);
  --alaska-logo-text-color: var(--darkBlue);

  /* nav bar of the selected page (example: Dashboard/Flights) */
  --page-nav-selected-text-color: var(--brightBlue);
  --page-nav-text: #080808;
  --page-nav-hover-text: var(--brightBlue);

  /* TODO clean up */
  --primary-container-background: var(--lightGray);
  --page-container-background: var(--lightGray);
  --secondary-page-container-background: white;
  --page-container-text: black;
  --page-container-header-text: var(--lightBlack);

  /* nav bar of the selected page (example:  Gantt View/Flights View) */
  --secondary-page-nav-background: white;
  --secondary-page-nav-text: var(--lightBlack);

  /* buttons */
  --button-focus-outline: #7e95a5;
  --primary-button-background: var(--brightBlue);
  --primary-button-disabled-background: var(--gray);
  --primary-button-text: white;
  --primary-button-hover-background: var(--brightBlue);

  --secondary-button-background: white;
  --secondary-button-text: var(--brightBlue);
  --secondary-button-disabled-background: var(--gray);
  --secondary-button-disabled-text: var(--gray);
  --secondary-text-color: rgba(0, 0, 0, 0.54);

  /* SearchInput */
  --primary-prev-next-navigation-section: #f8f8f8;

  /* Flight List Table */
  --table-odd-stripe: #f7f7f7;
  --now-line-color: var(--brightBlue);
  --selected-flight-border: var(--brightBlue);
  --selected-flight-puck-background: var(--lightBlue);
  --selected-flight-row-background: var(--lightestBlue);
  --flight-list-table-date-border: 1px solid var(--lightGray);
  --flight-list-table-date-background: #fff;
  --flights-no-data-background: #fff;
  --flights-no-data-text-color: #681515;

  /*Background color for flight phase - V2*/
  --puck-phase-highlighted-background: #0a9ec5;
  --puck-phase-default-background: #adadad;
  --puck-phase-planned: #646b78;

  /*Background color for swapped puck*/
  --puck-swap-flight-background: #aa005c;

  --puck-selected-group-background-transparent: rgba(129, 202, 255, 0.5);
  --puck-selected-group-background-overlay: rgba(129, 202, 255, 0.3);
  --puck-selected-group-border-color: rgba(129, 202, 255, 1);

  /* Background transparent colors*/
  --puck-phase-highlighted-background-transparent: rgba(10, 158, 197, 0.5);
  --puck-phase-default-background-transparent: rgba(173, 173, 173, 0.5);
  --puck-phase-planned-transparent: rgba(100, 107, 120, 0.5);
  --puck-block-default-background-transparent: rgba(131, 131, 131, 0.5);

  /*Blocks in puck*/
  --puck-block-highlighted-background: #0a728d;
  --puck-block-default-background: #838383;

  /*Puck early-delay indicator*/
  --puck-early-indicator: #00c900;
  --puck-delay-indicator: #ff0000;
  --puck-phase-early-indicator: #9bbc6d;
  --puck-phase-delay-indicator: #e26a43;

  /* Ground and flight puck styles */
  --puck-box-shadow: 0px 2px 3px 0px rgba(204, 204, 204, 0.5);
  --puck-label-color: #ffffff;

  /* Ground puck colors */
  --ground-puck-out-of-service-background: #f9d248;
  --ground-puck-out-of-service-text: #000000;
  --ground-puck-standby-background: #63854a;

  /* Block puck colors */
  --ground-puck-block-background: #7A46E6;
  --ground-puck-block-text: #fff;

  /*Color spectrum for Pie Chart*/
  --pie-background-default: #e1e1e1;
  --pie-background-1: #01426a;
  --pie-background-2: #0267a6;
  --pie-background-3: #2a96e4;
  --pie-background-4: #cce3f4;
  --pie-background-5: #e5f1f9;
  --pie-background-6: #40a8c4;
  --pie-background-7: #7fdbda;
  --pie-background-8: #a9fffd;
  --pie-background-9: #dcd6f7;
  --pie-background-10: #f4eeff;
  --pie-background-11: #8fd9a8;

  /*Pie chart - tooltip*/
  --pie-chart-tooltip-background: var(--brightBlue);
  --pie-chart-tooltip-border: var(--brightBlue);
  --pie-chart-tooltip-text: #ffffff;

  /* Data Validation Colors */
  --warning-color: #de750c;
  --error-color: #ed3838;

  /* Irrops Modal Colors */
  --fade-gray: #606060;

  /* Flight Navigation Bar */
  --flight-nav-bar-item-shadow: 0px 2px 4px 0px rgb(212, 212, 212);
  --flight-nav-bar-item-shadow-2: 0px 4px 2px -2px rgb(212 212 212);
  --flight-nav-bar-background: #f7f7f7;
  --flight-nav-bar-icon-background: #fff;
  --flight-nav-bar-icon-fill: var(--brightBlue);
  --flight-nav-bar-icon-disabled-fill: var(--darkerGray);
  --flight-nav-bar-search-icon-fill: #989898;
  --flight-nav-bar-search-text: var(--flight-nav-bar-search-icon-fill);

  --gantt-flight-toggle-selected-background: var(--brightBlue);
  --gantt-flight-toggle-unselected-background: #ffffff;
  --gantt-flight-toggle-selected-icon-fill: #ffffff;
  --gantt-flight-toggle-unselected-icon-fill: var(--brightBlue);

  /* Flifo */
  --flifo-search-icon-background: #525864;

  /* Gantt Chart */
  --gantt-chart-background: #fff;
  --gantt-chart-tailnumbers-background: #fff;
  --gantt-chart-timeline-background: #fff;
  --gantt-chart-border-color: var(--lightGray);

  --early-indicator-color: #5e8742;
  --delay-indicator-color: #df3e09;

  /* Inputs */
  --chip-input-background: #ededed;
  --dropdown-menu-background: #fff;

  /* Scrollbars */
  --scrollbar-background: #fff;
  --scrollbar-thumb-background: var(--gray);

  /* User Menu, Flight Access Menu */
  --menu-separator-line-color: var(--lightGray);
  --flight-action-menu-subheader-color: #5f5f5f;
  --flight-action-menu-arrow-color: var(--dropdown-menu-background);
  --flight-action-menu-box-shadow: 0px 2px 4px 0px rgb(174 174 174 / 50%);

  /* Filter Modal */
  --disabled-dropdown-icon-color: var(--darkerGray);
  --disabled-text-color: var(--darkerGray);

  /* Flight panel */
  --flight-panel-secondary-label: var(--darkerGray);

  /* Gantt Pucks */
  --puck-select-outline: #81caff;

  /* Equipment tool */
  --equipment-table-stripe: #f7f7f7;
  --equipment-table-header: #f7f7f7;

  /* Constant height of Timeline Axis Bar */
  --constant-timeline-axis-height: 4.2rem;
  --constant-timeline-tick-mark: 0.8rem;
  --constant-timeline-hour-text-height: 3.2rem;
  --constant-timeline-hour-text-line-height: 3.8rem;
  --constant-flight-number-font-size: 1.4rem;
  --constant-gantt-bottom-content-height: 20.4rem;
  --constant-gantt-bottom-content-height-hideheader: 14.4rem; /*when header is hidden and nosummary, no swapmode*/
  --constant-gantt-bottom-content-height-hideheader-summary-mini: 16.8rem; /*when header is hidden with sumamry mini and no swapmode*/
  --constant-gantt-bottom-content-height-hideheader-summary-expanded: 45.6rem; /*when header is hidden with sumamry expanded and no swapmode*/
  --constant-gantt-bottom-content-height-hideheader-swapmode: 21.7rem; /*when header is hidden and nosummary, no swapmode*/
  --constant-gantt-bottom-content-height-hideheader-summary-mini-swapmode: 24.7rem; /*when header is hidden with sumamry mini and no swapmode*/
  --constant-gantt-bottom-content-height-hideheader-summary-expanded-swapmode: 53.5rem; /*when header is hidden with sumamry expanded and no swapmode*/
  --constant-table-bottom-content-height-hideheader-no-summary: 10rem; /*when header is hidden and no summary panel*/
  --constant-table-bottom-content-height-hideheader-summary-mini: 12.5rem; /*when header is hidden with sumamry mini in table view*/
  --constant-table-bottom-content-height-hideheader-summary-expanded: 41.7rem; /*when header is hidden with sumamry expanded in table view*/

  --constant-timeline-daymark-font-size: 0.9rem;
  --constant-gantt-bottom-summary-mini-height: 2.9rem;
  --constant-gantt-bottom-summary-expanded-height: 31.7rem;
  --constant-gantt-bottom-summary-mini-height-swapmode: 3.4rem;
  --constant-gantt-bottom-summary-expanded-height-swapmode: 32.2rem;
  --constant-gantt-bottom-summary-expanded-height-portrait: 33.7rem;
  --constant-gantt-bottom-content-height-summary-mini: 22.8rem; /*20.4 is base. adding 2.9 for bottom summary height */
  --constant-gantt-bottom-content-height-summary-mini-portrait: 22.8rem;
  --constant-table-view-height-summary-mini: 18.5rem;
  --constant-gantt-bottom-content-height-summary-expanded: 51.7rem; /*20.4 is base. adding 31.7 for bottom summary height */
  --constant-table-view-height-summary-expanded: 47.7rem;
  --constant-table-view-height-summary-expanded-portrait: 50rem;
  --constant-gantt-bottom-content-height-summary-expanded-portrait: 74.1rem; /*20.4 is base. adding 53.7 for bottom summary height */
  --constant-gantt-bottom-content-height-summary-mini-swapmode: 30.5rem; /*20 is base. adding 2.9 for bottom summary height and (6.8 + 0.4) for swap foother */
  --constant-gantt-bottom-content-height-summary-expanded-swapmode: 59.3rem; /*20 is base. adding 31.7 for bottom summary height  and (6.8 + 0.4) for swap foother*/
  --constant-gantt-bottom-content-height-summary-expanded-swapmode-portrait: 81.3rem; /*20.4 is base. adding 53.7 for bottom summary height  and (6.8 + 0.4) for swap foother*/

  /* Multiple Views */
  --view-window-toolbar-background-color: #354255;
}

:root.modern-light-theme {
  /* Gantt Pucks */
  --puck-select-background: #ecf7ff;
  --puck-selected-group-background: #81caff;
  --puck-swap-flight-background: #c50099;
}

:root.high-contrast-theme {
  --gantt-chart-background-high-contrast: #000;
}

.modern-dark-theme,
:root.classic-theme {
  /* Dark mode color palette */
  --dark-primary: #1d212c;
  --dark-secondary: #2d3040;
  --dark-third: #282b39;
  --dark-fourth: #393e54;
  --dark-puck-phase-infoby: #ddae3c;
  --gray: #808399;

  /* TODO delete dark blues after converting all modals to v2 */
  --darkestBlue: #0b334f;
  --darkerBlue: #083250;
  --darkBlue: #01426a;
  --lightBlack: #212223;
  --lightGray: #eeeeee;
  /* --gray: #d9d9d9; */
  --darkerGray: #979797;
  --brightBlue: #0074c8; /* interaction blue */
  --lightBlue: #cce3f4;
  --lightestBlue: #e5f1f9;

  --darkModeGray: #3a3c4b;
  --lightBlue: #81caff;

  /******** Web page parts ********/

  /* Icons */
  --primary-icon-color: var(--lightBlue);
  --secondary-icon-color: var(--gray);
  --info-icon-color: var(#000000);

  /* Borders */
  --primary-border-color: var(--darkModeGray);
  --secondary-border-color: var(--gray);

  /* Hover */
  --primary-hover-color: #4e5164;
  --secondary-hover-color: var(--lightBlue);

  /* Links */
  --interaction-link-color: var(--lightBlue);
  --unfocused-interaction-link-color: #fff;
  --access-menu-link-color: #fff;

  /* primary header is the top most header of the web app */
  --primary-header-background: var(--dark-secondary);
  --primary-header-center-background: var(--dark-fourth);
  --alaska-logo-icon-color: #fff;
  --alaska-logo-text-color: #fff;

  /* nav bar of the selected page (example: Dashboard/Flights) */
  --page-nav-selected-text-color: #fff;
  --page-nav-text: #fff;
  --page-nav-hover-text: #fff;

  /* TODO clean up */
  --primary-container-background: var(--dark-primary);
  --page-container-background: var(--dark-secondary);
  --secondary-page-container-background: var(--dark-third);
  --page-container-text: #fff;
  --page-container-header-text: var(--lightBlack);

  /* header of selected page after selecting from page navigation (example: the flights page header)*/
  --page-header-background: white;

  /* nav bar of the selected page (example:  Gantt View/Flights View) */
  --secondary-page-nav-background: white;
  --secondary-page-nav-text: var(--lightBlack);

  /* buttons */
  --button-focus-outline: #7e95a5;
  --button-hover-background: #90cffd;
  --primary-button-background: var(--lightBlue);
  --primary-button-disabled-background: var(--gray);
  --primary-button-text: var(--dark-secondary);
  --primary-button-hover-background: var(--primary-hover-color);

  --secondary-button-background: white;
  --secondary-button-text: var(--brightBlue);
  --secondary-button-disabled-background: var(--gray);
  --secondary-button-disabled-text: var(--gray);
  --secondary-text-color: var(--gray);

  /* SearchInput */
  --primary-prev-next-navigation-section: var(--dark-fourth);

  /* Flight List Table */
  --table-odd-stripe: #f7f7f7;
  --now-line-color: #fff;
  --selected-flight-border: var(--lightBlue);
  --selected-flight-puck-background: var(--lightBlue);
  --selected-flight-row-background: rgba(129, 202, 255, 0.2);
  --flight-list-table-date-border: none;
  --flight-list-table-date-background: var(--dark-third);
  --flights-no-data-background: var(--dark-third);
  --flights-no-data-text-color: #fff;

  /*Background color for flight phase - V2*/
  --puck-phase-highlighted-background: #0a9ec5;
  --puck-phase-default-background: #adadad;
  --puck-phase-planned: #646b78;

  /* Background transparent colors*/
  --puck-phase-highlighted-background-transparent: rgba(10, 158, 197, 0.5);
  --puck-phase-default-background-transparent: rgba(173, 173, 173, 0.5);
  --puck-phase-planned-transparent: rgba(100, 107, 120, 0.5);

  /*Blocks in puck*/
  --puck-block-highlighted-background: #0a728d;
  --puck-block-default-background: #838383;

  /*Puck early-delay indicator*/
  --puck-early-indicator: #5fb700;
  --puck-delay-indicator: #ff0000;

  /* Ground and flight puck styles */
  --puck-box-shadow: none;
  --puck-label-color: #ffffff;
  --flight-number-color-out-on: #000000;
  --flight-number-color-tailbreak: #000000;

  /* Ground puck colors */
  --ground-puck-out-of-service-background: #f9d248;
  --ground-puck-out-of-service-text: #000000;
  --ground-puck-standby-background: #63854a;

  /*Color spectrum for Pie Chart*/
  --pie-background-default: #e1e1e1;
  --pie-background-1: #01426a;
  --pie-background-2: #0267a6;
  --pie-background-3: #2a96e4;
  --pie-background-4: #cce3f4;
  --pie-background-5: #e5f1f9;
  --pie-background-6: #40a8c4;
  --pie-background-7: #7fdbda;
  --pie-background-8: #a9fffd;
  --pie-background-9: #dcd6f7;
  --pie-background-10: #f4eeff;
  --pie-background-11: #8fd9a8;

  /*Pie chart - tooltip*/
  --pie-chart-tooltip-background: var(--brightBlue);
  --pie-chart-tooltip-border: var(--brightBlue);
  --pie-chart-tooltip-text: #ffffff;

  /* Data Validation Colors */
  --warning-color: #de750c;
  --error-color: #ed3838;

  /* Irrops Modal Colors */
  --fade-gray: #606060;

  /* Flight Navigation Bar */
  --flight-nav-bar-item-shadow: none;
  --flight-nav-bar-item-shadow-2: none;
  --flight-nav-bar-background: var(--dark-primary);
  --flight-nav-bar-icon-background: var(--dark-secondary);
  --flight-nav-bar-icon-fill: var(--lightBlue);
  --flight-nav-bar-search-icon-fill: var(--lightBlue);
  --flight-nav-bar-search-text: #a6a6a6;

  --gantt-flight-toggle-selected-background: var(--lightBlue);
  --gantt-flight-toggle-unselected-background: var(--dark-secondary);
  --gantt-flight-toggle-selected-icon-fill: var(--dark-secondary);
  --gantt-flight-toggle-unselected-icon-fill: #ffffff;

  /* Gantt Chart */
  --gantt-chart-background: var(--dark-primary);
  --gantt-chart-tailnumbers-background: var(--dark-primary);
  --gantt-chart-timeline-background: var(--dark-third);

  --gantt-chart-border-color: var(--darkModeGray);

  --early-indicator-color: #69cf96;
  --delay-indicator-color: #ff999b;

  /* Equipment tool */
  --equipment-table-stripe: #242936;
  --equipment-table-header: #363948;

  /* Inputs */
  --chip-input-background: var(--dark-secondary);
  --dropdown-menu-background: var(--dark-secondary);

  /* Scrollbars */
  --scrollbar-background: var(--dark-third);
  --scrollbar-thumb-background: var(--gray);

  /* User Menu, Flight Access Menu */
  --menu-separator-line-color: #808399;
  --flight-action-menu-subheader-color: #808399;
  --flight-action-menu-arrow-color: var(--dropdown-menu-background);
  --flight-action-menu-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

  /* Filter Modal */
  --disabled-dropdown-icon-color: var(--gray);
  --disabled-text-color: var(--gray);

  /* Flight panel */
  --flight-panel-secondary-label: var(--gray);

  /* Gantt pucks*/
  --puck-select-background: rgba(89, 134, 171, 0.6);

  /* Multiple Views */
  --view-window-toolbar-background-color: #354255;
}

:root.classic-theme {
  /* Flight puck color for classic theme */
  --puck-phase-adv: #f9d248;
  --puck-phase-etr: #dbdbdb;
  --puck-phase-out: #91cc3f;
  --puck-phase-off: #007695;
  --puck-phase-on-old: #48bede;
  --puck-phase-on: #00e2aa;
  --puck-phase-in: #4d515c;
  --puck-phase-planned: #623163;
  --puck-phase-delayed: #c50000;
  --puck-phase-watch: #e5667d;
  --puck-phase-tailbreak: #deffbd;
  --puck-phase-infoby: #ddae3c;
  --puck-phase-swap: #aa005c;
  --puck-phase-standby: #67854a;
  --puck-phase-maint: #7a46e6;
  --puck-phase-eqip: #e26a43;
  --puck-phase-shortturn: #cd93ff;
  --puck-phase-early-light: #5fb700;
  --puck-phase-early-dark: #2c5400;
  --puck-phase-late-light: #dc0101;
  --puck-phase-late-dark: #890000;
}

:root.classic-theme {
  /* Short flight puck transparent color for classic theme */
  --puck-phase-adv-transparent: rgba(221, 174, 60, 0.5);
  --puck-phase-etr-transparent: rgba(219, 219, 219, 0.5);
  --puck-phase-out-transparent: rgba(145, 204, 63, 0.5);
  --puck-phase-off-transparent: rgba(0, 118, 149, 0.5);
  --puck-phase-on-old-transparent: rgba(72, 190, 222, 0.5);
  --puck-phase-on-transparent: rgba(10, 158, 197, 0.5);
  --puck-phase-in-transparent: rgba(77, 81, 92, 0.5);
  --puck-phase-planned-transparent: rgba(100, 107, 120, 0.5);
  --puck-phase-delayed-transparent: rgba(197, 0, 0, 0.5);
  --puck-phase-watch-transparent: rgba(229, 102, 125, 0.5);
  --puck-phase-swap-transparent: rgba(170, 0, 92, 0.5);
  --puck-phase-standby-transparent: rgba(103, 133, 74, 0.5);
  --puck-phase-maint-transparent: rgba(122, 70, 230, 0.5);
  --puck-phase-eqip-transparent: rgba(226, 106, 67, 0.5);
  --puck-phase-early-light-transparent: rgba(95, 183, 0, 0.5);
  --puck-phase-early-dark-transparent: rgba(44, 84, 0, 0.5);
  --puck-phase-late-light-transparent: rgba(220, 1, 1, 0.5);
  --puck-phase-late-dark-transparent: rgba(137, 0, 0, 0.5);
}

body {
  margin: 0;
  color: var(--page-container-text);
  background-color: var(--page-container-background);
  font-family: var(--defaultFontFamily);
  font-size: var(--defaultFontSize);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* default style for all scrollbars in the application */
*::-webkit-scrollbar {
  width: 1.2rem;
  height: 1.4rem;
}

/* scrollbar - track */
*::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}

*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-background);
  border-radius: 1rem;
  border: 0.2rem solid var(--scrollbar-background);
}
