.assignment-form-sub-container {
  display: flex;
  justify-content: space-between;
}

.assignment-airline-item,
.assignment-type-item {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex: auto;
}
.assignment-airline-container,
.assignment-type-container {
  display: flex;
}

.role-form-airline {
  margin-bottom: 1rem;
}
/* material ui overrides */

.assignment-form-container
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100%;
  margin-bottom: 1.6rem;
}

.MuiFormControl-root.MuiTextField-root.text-field {
  display: block;
}

/* form label colors on focus */
.assignment-form-container .MuiFormLabel-root.Mui-focused {
  color: #656565;
}

/* text field underline color on focus, */
.assignment-form-container .MuiInput-underline:after {
  border-bottom-color: #656565;
}
